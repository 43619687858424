import React, { Component } from 'react';
import { Form, Col, Button} from 'react-bootstrap';
import { withRouter } from "react-router";
import userService from "servicios/usuarioService";
import "style/searchview.css"
class UsuarioBuscar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //user data
            name: '',
            lastname: '',
            dni: '',
            email: '',
            isLock: false,
            isActive: true,
            dnitype: 'V',

            //view state
            formvalidated: false,
            showSearch: true,
            
        };
        this.handleSubmit.bind(this);
    }

    
    

    handleSubmit = (event)=>{
        
        event.preventDefault();
        event.stopPropagation();
        userService.find(this.state).then(r=>{
            this.props.history.push(`/usuarios/${r.data[0].id}`);
        })
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit} className="search-form mx-auto">
                <Form.Row >
                    <Form.Group as={Col} controlId="name">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            value={this.state.name}
                            onChange={(event) => { this.setState({ name: event.target.value }); }}
                            type="text" placeholder="Nombre" />
                    </Form.Group>
                </Form.Row>
                <Form.Row >
                    <Form.Group as={Col} controlId="dni">
                        <Form.Label>Cédula</Form.Label>
                        <Form.Control
                            value={this.state.dni}
                            onChange={(event) => { this.setState({ dni: event.target.value }); }}
                            type="numeric" placeholder="Cédula" />
                    </Form.Group>
                </Form.Row>
                <Form.Row >
                    <Form.Group as={Col} controlId="name">
                    <Form.Label>Correo Electrónico</Form.Label>
                        <Form.Control
                                value={this.state.email} 
                                onChange={(event)=>{this.setState({email:`${event.target.value}`})}}
                                type="email" 
                                placeholder="ejemplo@ejemplo.com" />
                    </Form.Group>
                </Form.Row>

                <Form.Row className="align-items-center my-8">
                    <Col className="mx-2">
                        <span></span>
                    </Col>
                    <Col className="mx-8">
                        <Button type="submit" block>Buscar</Button>
                    </Col>
                    <Col className="mx-2">
                        <span></span>
                    </Col>
                </Form.Row>
            </Form>
        );
    }

}
export default withRouter(UsuarioBuscar);