import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { withRouter } from "react-router";

// TODO: pruebas con material-ui
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
class LoginNavBar extends Component {
  logThis = () => {
    this.props.history.push("/ingreso");
  };

  render() {
    return (
      <>
        <AppBar></AppBar>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          style={{ backgroundColor: "#10699B" }}
        >
          <Navbar.Brand href="/">
            <Typography variant="h6" noWrap>
              SIGES
            </Typography>
          </Navbar.Brand>

          <Typography variant="body2" noWrap style={{ color: "white" }}>
            &nbsp;ver 0.1
          </Typography>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className="ml-auto"
              style={{
                display: this.props.visible ? this.props.visible : "none",
              }}
            ></Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
export default withRouter(LoginNavBar);
