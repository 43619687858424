import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  createMuiTheme,
  Card,
  CardHeader,
  CardContent,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";

import { esES } from "@material-ui/core/locale";
//componentes
import { useForm, Form } from "componentes/useForm";
import Controls from "componentes/controls/Controls";
//servicios
import personalService from "servicios/personalService";
import empresaService from "servicios/empresaService";
import sedeService from "servicios/sedeService";
import rolService from "servicios/rolservice";
import bancosService from "servicios/bancosService";

import { get } from "react-hook-form";
// icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      "& .MuiInputBase-root": {
        padding: theme.spacing(0.5),
      },
      "& .MuiOutlinedInput-inputMultiline": {
        padding: theme.spacing(2),
      },
      "& .MuiFormControl-root": {
        width: "100%",
        margin: theme.spacing(0.5),
        padding: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
      "& > * + *": {
        marginTop: theme.spacing(0.5),
      },
    },
    pageContent: { margin: theme.spacing(1), padding: theme.spacing(1) },
    media: {
      height: 200,
    },
    paper: {
      paddingVertical: theme.spacing(1),
      textAlign: "left",
      color: theme.palette.text.secondary,
      paddingBottom: 6,
      paddingTop: 2,
      paddingLeft: 6,
      paddingRight: 6,
    },
    fontFecha: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      width: 200,
    },
    textarea: {
      width: "90%",
    },
    normalLong: {
      width: "25%",
    },
  }),
  esES
);

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 12,
  },
});

const initialFValues = {
  id: 0,
  name: "",
  lastname: "",
  dni: "",
  isActive: true,
  dnitype: "V",
  observation: "",
  phone: "",
  address: "",
  email: "",

  job: -1,
  isSystemUser: false,
  isPasswordSet: false,
  username: "",
  password: "",
  password2: "",
  role: 0,
  empresa: 0,
  sede: 0,

  bankid: 0,
  accounttype: "1",
  accountno: "",
  isownaccount: true,
  accountdni: "",
  accountname: "",
};

const tipos = [
  { id: "V", name: "VENEZOLANO" },
  { id: "E", name: "EXTRANJERO" },
  { id: "N/A", name: "N/A" },
];

const tipocuenta = [
  { id: 1, name: "Ahorro" },
  { id: 2, name: "Corriente" },
];

function PersonalForm(props) {
  const classes = useStyles();
  const [sedes, setSedes] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [roles, setRoles] = useState([]);
  const { addOrEdit, recordForEdit } = props;

  //validacion de formulario
  const validate = (fieldValues = values) => {
    Object.entries(fieldValues).forEach((entry) => {
      const [key, value] = entry;
      if (
        typeof value !== "number" &&
        value !== null &&
        typeof value !== "boolean"
      ) {
        (key === "email" || key === "password")
          ? setValues({ ...values, [key]: value })
          : setValues({ ...values, [key]: value.toUpperCase() });
      }
    });
    let temp = { ...errors };

    if ("empresa" in fieldValues)
      temp.empresa =
        fieldValues.empresa.length !== 0 ? "" : "Campo obligatorio";

    if ("sede" in fieldValues)
      temp.sede = fieldValues.sede.length !== 0 ? "" : "Campo obligatorio";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  async function getSedes() {
    try {
      let sedesDB = await sedeService.find();
      setSedes(sedesDB.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  async function getEmpresas() {
    try {
      let empresasDB = await empresaService.getAll();
      setEmpresas(empresasDB.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  async function getRol() {
    try {
      let rolDB = await rolService.find();
      setRoles(rolDB.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  useEffect(() => {
    getSedes();
    getEmpresas();
    getRol();
    if (recordForEdit !== null) {
      setValues({ ...recordForEdit });
      console.log("valores edit=>", recordForEdit);
    }
  }, [recordForEdit, setValues]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={6}>
              <Controls.Input
                name="name"
                label="Nombre"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="lastname"
                label="Apellido"
                value={values.lastname}
                onChange={handleInputChange}
                error={errors.lastname}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="sede"
                label="Sede"
                value={values.sede}
                onChange={handleInputChange}
                options={sedes}
                campo={"nombre"}
                error={errors.sede}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="empresa"
                label="Empresa"
                value={values.empresa}
                onChange={handleInputChange}
                options={empresas}
                campo={"nombre"}
                error={errors.empresa}
              />
              {console.log("empresa", values)}
            </Grid>
            <Grid item xs={4}>
              <Controls.Select
                name="dnitype"
                label="Cédula"
                value={values.dnitype}
                onChange={handleInputChange}
                options={tipos}
                campo={"name"}
                error={errors.dnitype}
              />
            </Grid>
            <Grid item xs={4}>
              <Controls.Input
                name="dni"
                label=""
                value={values.dni}
                onChange={handleInputChange}
                error={errors.dni}
              />
            </Grid>
            <Grid item xs={4}>
              <Controls.Input
                name="phone"
                label="Teléfono"
                value={values.phone}
                onChange={handleInputChange}
                error={errors.phone}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                name="address"
                label="Dirección"
                value={values.address}
                onChange={handleInputChange}
                error={errors.address}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                name="observation"
                label="Observaciones"
                value={values.observation}
                onChange={handleInputChange}
                error={errors.observation}
              />
            </Grid>

            <Grid item xs={12}>
              <Controls.CheckboxLabels
                name="isSystemUser"
                label="Es usuario del sistema"
                checked={values.isSystemUser}
                onChange={(v) =>
                  setValues({ ...values, ["isSystemUser"]: v.target.checked })
                }
              />
            </Grid>
            {values.isSystemUser === true && (
              <>
                <Card className={classes.root}>
                  <CardHeader title="Datos de Usuario" />
                  <CardContent>
                    <div style={{ width: "100%" }}>
                      <Box display="flex" p={1} bgcolor="background.paper">
                        <Box p={1} flexGrow={1}>
                          <Controls.Input
                            name="email"
                            label="Usuario"
                            value={values.email}
                            onChange={handleInputChange}
                            error={errors.email}
                          />
                        </Box>
                        <Box p={1} flexGrow={1}>
                          <Controls.Select
                            name="role"
                            label="Rol"
                            value={values.role}
                            onChange={handleInputChange}
                            options={roles}
                            campo={"name"}
                            error={errors.role}
                          />
                        </Box>
                        <Box p={1} flexGrow={1}>
                          <Controls.Input
                            name="password"
                            type="password"
                            label="Contraseña"
                            value={values.password}
                            onChange={handleInputChange}
                            error={errors.password}
                          />
                        </Box>
                      </Box>
                    </div>
                  </CardContent>
                </Card>
              </>
            )}
            <div style={{ width: "100%" }}></div>
            <Grid item xs={12}>
              <div>
                <Controls.Button type="submit" text="Enviar" />
                <Controls.Button
                  text="Limpiar"
                  color="default"
                  onClick={resetForm}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

export default PersonalForm;
