import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import FooterC from "./componentes/FooterC";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Empresa from 'componentes/empresa/Empresa';
import Empresa2 from 'componentes/empresa/Empresa2';
import EmpresaBuscar from 'componentes/empresa/EmpresaBuscar';
import EmpresaReport from 'componentes/empresa/EmpresaReport';
import Home from "./vistas/home/Home";
import Ingreso from "./vistas/ingreso/Ingreso";
import Login from "./vistas/Login";

import MiUsuario from 'componentes/personal/MiUsuario';
import Personal from 'componentes/personal/Personal';
import Personal2 from 'componentes/personal/Personal2';
import PersonalBuscar from 'componentes/personal/PersonalBuscar';
import PersonalReport from 'componentes/personal/PersonalReport';
import Usuario from 'componentes/usuario/Usuario';
import UsuarioBuscar from 'componentes/usuario/UsuarioBuscar';
import UsuarioReport from 'componentes/usuario/UsuarioReport';

import Estado from 'componentes/estados/Estado';
import Sede from "componentes/sedes/Sede";
import Divisa from "componentes/divisas/Divisa";
import Recaudacion from "componentes/recaudacion/Recaudacion";
import Reporte from "componentes/reportes/Reportes";

import axiosconf from 'servicios/axios'

function App() {

  axiosconf();
  return (
    <Router basename={"/"}>
      <Switch>
        <Route exact path="/">
          {/**
          <Ingreso />
           */}
          <Login />
        </Route>
        <Route path="/home">
          <Home name="Bienvenido" />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/ingreso">
          <Ingreso />
        </Route>
        <Route
          path="/usuarios/buscar"
          render={() => (
            <Home name="Buscar Usuario">
              <UsuarioBuscar></UsuarioBuscar>
            </Home>
          )}
        ></Route>
        <Route
          path="/usuarios/reporte"
          render={() => (
            <Home name="Reporte de Usuarios">
              <UsuarioReport />
            </Home>
          )}
        ></Route>
        <Route
          path="/usuarios/:id"
          render={() => (
            <Home name="Usuarios">
              <Usuario modificar={false}></Usuario>
            </Home>
          )}
        ></Route>
        <Route
          path="/usuarios"
          render={() => (
            <Home name="Usuarios">
              <Usuario></Usuario>
            </Home>
          )}
        ></Route>

        <Route
          path="/personal/buscar"
          render={() => (
            <Home name="Personal">
              <PersonalBuscar />
            </Home>
          )}
        ></Route>
        <Route
          path="/personal/reporte"
          render={() => (
            <Home name="Reporte de Personal">
              <PersonalReport />
            </Home>
          )}
        ></Route>
        <Route
          path="/personal/:id"
          render={() => (
            <Home name="Personal">
              <Personal />
            </Home>
          )}
        ></Route>
        <Route
          path="/personal"
          render={() => (
            <Home name="">
              <Personal2 />
            </Home>
          )}
        ></Route>
        <Route
          path="/empresa/buscar"
          render={() => (
            <Home name="Empresa">
              <EmpresaBuscar />
            </Home>
          )}
        ></Route>
        <Route
          path="/empresa/reporte"
          render={() => (
            <Home name="Reporte de Empresas">
              <EmpresaReport />
            </Home>
          )}
        ></Route>
        <Route
          path="/empresa/:id"
          render={() => (
            <Home name="Empresa">
              <Empresa />
            </Home>
          )}
        ></Route>
        <Route
          path="/empresa"
          render={() => (
            <Home name="">
              <Empresa2 />
            </Home>
          )}
        ></Route>
        <Route
          path="/miusuario"
          render={() => (
            <Home name="">
              <MiUsuario />
            </Home>
          )}
        ></Route>

        <Route
          path="/estado"
          render={() => (
            <Home name="">
              <Estado />
            </Home>
          )}
        ></Route>
        <Route
          path="/estado/:id"
          render={() => (
            <Home name="">
              <Estado />
            </Home>
          )}
        ></Route>
        <Route
          path="/sede"
          render={() => (
            <Home name="">
              <Sede />
            </Home>
          )}
        ></Route>
        <Route
          path="/sede/:id"
          render={() => (
            <Home name="">
              <Sede />
            </Home>
          )}
        ></Route>
        <Route
          path="/divisa"
          render={() => (
            <Home name="">
              <Divisa />
            </Home>
          )}
        ></Route>
        <Route
          path="/divisa/:id"
          render={() => (
            <Home name="">
              <Divisa />
            </Home>
          )}
        ></Route>
        <Route
          path="/recaudacion"
          render={() => (
            <Home name="">
              <Recaudacion />
            </Home>
          )}
        ></Route>
        <Route
          path="/recaudacion/:id"
          render={() => (
            <Home name="">
              <Recaudacion />
            </Home>
          )}
        ></Route>
        <Route
          path="/reportes"
          render={() => (
            <Home name="">
              <Reporte />
            </Home>
          )}
        ></Route>

      </Switch>
      <div>
        <FooterC></FooterC>
      </div>
    </Router>
  );
}

export default App;
/*
function LogoutRoutes() {
  return (
    <Switch>
      <Route path="/signup" component={Login} default></Route>
    </Switch>
  );
}*/
