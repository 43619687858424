import React, { useEffect, useState } from "react";
import {useInterval} from 'componentes/comunes/useInterval';
import Notification from "componentes/controls/Notifications";
import Controls from "componentes/controls/Controls";
//jsPDF
import jsPDF from "jspdf";
import 'jspdf-autotable';
import {COPY2, imgDivisa} from "componentes/comunes/imagenes";
import CircularProgress from "@material-ui/core/CircularProgress";

//Material-ui
import { DataGrid } from "@material-ui/data-grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";

//axios
import Axios from "axios";

import servicio from "servicios/recaudacionService";
import estadoService from 'servicios/estadoService';
import sedeService from 'servicios/sedeService';

//user
import { getRol, getUserId } from "helpers/auth-helpers";

//Estilos
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
      "& .MuiInputBase-root": {
        padding: theme.spacing(0.5),
      },
      "& .MuiOutlinedInput-inputMultiline": {
        padding: theme.spacing(2),
      },
      "& .MuiFormControl-root": {
        width: "100%",
        margin: theme.spacing(0.5),
        padding: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
      "& > * + *": {
        marginTop: theme.spacing(0.5),
      },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 200,
    maxWidth: 350,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  avatar: {
    backgroundColor: red[500],
  },
}));


export default function Reportes() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  let offset = -4.5;
  let d = new Date();
  let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
  let nd = new Date(utc + (3600000*offset));

  const initialFValues = {
    from: nd,
    to: nd,
    dias: false,
    estado: null,
    sede: null,
    diassede: false,
  };

  const [cargador, setCargador] = useState('Cargando datos...');
  const [cargadort, setCargadort] = useState(null);
  const [values, setValues] = useState(initialFValues);
  const [access, setAccess] = useState(true);
  const [spinner, setSpinner] = useState(true);
  const [estado, setEstado] = useState([]);
  const [sede, setSede] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    if (getRol()<3) {
    }
  }, []);

  const convertToDefEventPara = (tipo, e) => {
    setValues({ ...values, [tipo]: e });
    if (tipo==='to' && e<values.from) {
      setValues({...values, from: e, to: e});
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    if (name==='estado' && value==='') {
      setValues({...values, estado: null, sede: null});
    } else if (name==='sede' && value==='') {
      setValues({...values, sede: null});
    } else {
      setValues({...values, [name]: value});
    }
  };

  const handleChangeCheck = (e) => {
    const {name, value} = e.target;
    setValues({...values, [name]: !values[name]});
  };

  const convertDate = (date) => {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth()+1).toString();
    var dd  = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
  }

  const columnasPDF = values.diassede? ['Sede', 'Total', 'Detalle'] : ['Sede', 'Total'];

  const pdfdownolad = () => {

    setSpinner(false);
    servicio.reporte(values)
    .then((r) => {
      let item = [];
      item.total = r.data;
      generate(r.data)
    })
    .catch((e)=>{
      console.log(e.message);
      setNotify({
        isOpen:true,
        message: e.message,
        type: "warning"
      });
      setSpinner(true);
    })
    .finally()
  };

  const today = new Date();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const isLiteralObject = (a) => {
    return (!!a) && (a.constructor === Object);
  };

  function generate(item) {
      var base64Img = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyBAMAAADsEZWCAAAAG1BMVEXMzMyWlpaqqqq3t7exsbGcnJy+vr6jo6PFxcUFpPI/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQUlEQVQ4jWNgGAWjgP6ASdncAEaiAhaGiACmFhCJLsMaIiDAEQEi0WXYEiMCOCJAJIY9KuYGTC0gknpuHwXDGwAA5fsIZw0iYWYAAAAASUVORK5CYII='
      var today = new Date();

      var yyyy = today.getFullYear().toString();
      var mm = (today.getMonth()+1).toString();
      var dd  = today.getDate().toString();
      var hh  = today.getHours().toString();
      var mmm  = today.getMinutes().toString();

      var mmChars = mm.split('');
      var ddChars = dd.split('');
      var hhChars = hh.split('');
      var mmmChars = mmm.split('');

      var fecha =
        (ddChars[1]?dd:"0"+ddChars[0]) +
        "-" +
        (mmChars[1]?mm:"0"+mmChars[0]) +
        "-" +
        today.getFullYear() +
        " / " +
        (hhChars[1]?hh:"0"+hhChars[0]) +
        ":" +
        (mmmChars[1]?mmm:"0"+mmmChars[0]);

      let titleHeader = "Resumen de divisas";
      let columns = columnasPDF;
      let rows = [];
      rows.push(['','','','','']);
      let titulo1Head = "Resumen";
      let noPag = false;
      let fontSize = 12;
      let headStyles1 = {
        1: { halign: 'center' },
        2: { columnWidth: 120, halign: 'center' },
        3: { halign: 'right' },
        4: { halign: 'right' },
      };
      let columnStyles1 ={
        1: { halign: 'center' },
        2: { columnWidth: 120, halign: 'right' },
        3: { columnWidth: 120, halign: 'right' },
        4: { halign: 'right' },
      };
      let imgHeader = {imgDivisa}.imgDivisa;
      let textFooter = {COPY2}.COPY2;
      let ordenNumero= "";// + padLeft(item.cliente[0].id , 7, '0');
      let tops;
      let head1 = [
        {id:{content: titulo1Head?titulo1Head:'Resumen de divisas', colSpan: 2, styles: {halign: 'center', fillColor: [22, 160, 133]}}},
      ];
      let head = [];
      for (var i = 0; i < item.total.length; i++) {
        for (var j = 0; j < item.total[i].length; j++) {
          head.push([item.total[i][j].dia, "$" + item.total[i][j].total]);
        }
      }
//      head.push([item.total[0][0].dia, "$"+item.total[0][0].total]);
      var doc = new jsPDF('p', 'pt', 'letter')
      const pdfPageWidth = doc.internal.pageSize.getWidth();

      doc.setFontSize(fontSize?fontSize:11);

      if (head) {
        doc.autoTable( {
            startY: 100,
            head: head1,
            body: head,
            columnStyles: {
                0: {cellWidth: 250},
                1: {cellWidth: pdfPageWidth-250-80},
              },
            margin: { top: 100 },
            drawHeaderCell: function (cell, data) {
                if (cell.raw === ' ') {//paint.Name header red
                    cell.styles.fontSize= 12;
                } else {
                    cell.styles.textColor = 255;
                    cell.styles.fontSize = 10;
                }
            },
            didParseCell: function (cell, data) {
              if (cell.raw === 'Jack') {
                 cell.styles.fontSize= 12;
                 cell.styles.textColor = [255,0,0];
              }
            },
            theme:'plain'
        });
      }

    doc.autoTable({ margin: {top: 30 }, theme:'plain' })
    var totalPagesExp = "{total_pages_count_string}";

    // datos del encabezado del reporte
    var header = function(data) {
        var xOffset = (doc.internal.pageSize.width ) - (doc.getStringUnitWidth(fecha) * doc.internal.getFontSize()) - data.settings.margin.left;
        var xOffset2 = (doc.internal.pageSize.width ) - (doc.getStringUnitWidth(ordenNumero) * doc.internal.getFontSize()) - data.settings.margin.left - 15;
        doc.setFontSize(12);
        doc.setTextColor(40);
        doc.addImage(
            imgHeader
            ?imgHeader
            :base64Img,
            'JPEG',
            data.settings.margin.left+5,
            35, 80, 50);
        doc.text(ordenNumero?ordenNumero:'', xOffset2, 45);
        doc.text(titleHeader, 130, 65);
        doc.setFontSize(10);
        doc.text(fecha, xOffset, 65);
    };
    // datos en el pie de página del reporte
    var footer = function (data) {
        doc.setFontSize(8);
        doc.setTextColor(150);
        if (noPag) {
            var str = "Pág " + data.pageCount;
            str = str + " de " + totalPagesExp;
            doc.text(str, pdfPageWidth-data.settings.margin.right - 20, doc.internal.pageSize.height - 20);
        }
        doc.text(data.settings.margin.left - 20, doc.internal.pageSize.height - 20, textFooter);
    };

    var alignCol = function(data){
        var col = data.column.index;
        if(col==1 || col==2 ){
            data.cell.styles.halign = 'right';
        }
    };
    // opciones generales
    // doc.text(text, x, y)
    for (let clave in item){
      if (isLiteralObject(item[clave]) && item[clave] !== null) {
        doc.setFontSize(14);
        doc.text("Estado " +item[clave].name, 40, doc.autoTable.previous.finalY );
        doc.setFontSize(fontSize?fontSize:11);
        let rowxs = item[clave].items;
//        let rowxs = parse3(item[clave].items.res);
        var options = {
          beforePageContent: header,
          margin: { top: 10 },
          startY: tops?tops:doc.lastAutoTable.finalY+10,
          headStyles: headStyles1?headStyles1 : {
              0: { columnWidth: 260},
              1: { halign: 'center' },
              2: { columnWidth: 60, halign: 'right' },
              3: { halign: 'right' },
          },
          columnStyles: columnStyles1?columnStyles1 : {
              0: { columnWidth: 260},
              1: { halign: 'center' },
              2: { columnWidth: 60, halign: 'left' },
              3: { halign: 'right' },
          },
          styles: {
            fontSize: 9,
            overflow: 'linebreak',
            whitespace: 'pre'
          },
          drawCell: function(cell, data) {
              cell.styles.fontSize = 9;
          },
          didParseCell: function (table) {
            if (table.section === 'head') {
              table.cell.styles.fontSize = 12;
              var col = table.column.index;
              if(col==1 || col==2 ){
                  table.cell.styles.halign = 'center';
              }
            }
//            alignCol(cell, data);
          },
          afterPageContent: footer
        };

        doc.autoTable(columns, rowxs, options);
        doc.autoTable({ margin: {top: 30 }, theme:'plain' })
      }
    }
    setSpinner(true);
    doc.setProperties({
        title: 'Reporte',
        subject: 'reporte',
        author: 'ARcorp developers',
        keywords: 'generated, javascript, web 2.0, ajax',
        creator: 'jsPDF'
    });

    doc.save( 'reporte.pdf');
  };

  async function getAllEstado() {
    try {
      let estados = await estadoService.getEreport();
      setEstado(estados.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  async function changeSede(e) {
    setSede([]);
    try {
      let sede = await sedeService.getEreport(e.target.value);
      setSede(sede.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  useEffect(() => {
    getAllEstado();
  }, [values]);

  return (
    <>
      <Table>
        <Box width="100%">
          <h4>Reportes 📈</h4>
        </Box>
      </Table>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <Controls.Pickers
            name="from"
            label="Desde"
            disableFuture={true}
            maxDate={values.to}
            value={values.from}
            onChange={e => convertToDefEventPara('from',e)}
          />
        </Grid>

        <Grid item xs={4}>
          <Controls.Pickers
            name="to"
            label="Hasta"
            disableFuture={true}
            value={values.to}
            onChange={e => convertToDefEventPara('to',e)}
          />
        </Grid>

        <Grid item xs={4}>
          <Controls.CheckboxLabels
            name="dias"
            value={values.dias}
            onChange={handleChangeCheck}
            label="Mostrar días"
            checked={values.dias}
          />
        </Grid>

        <Grid item xs={12}>
        &nbsp;
        </Grid>

        <Grid item xs={4}>
          <Controls.Select
            name="estado"
            label="Estado"
            value={values.estado}
            onChange={(e) =>{handleChange(e); changeSede(e)}}
            options={estado}
            campo={"nombre"}
            Tam = {241}
          />
        </Grid>

        <Grid item xs={4}>
          <Controls.Select
            name="sede"
            label="Sede"
            value={values.sede}
            onChange={handleChange}
            options={sede}
            campo={"nombre"}
            Tam = {241}
          />
        </Grid>

        <Grid item xs={4}>
          <Controls.CheckboxLabels
            name="diassede"
            value={values.diassede}
            onChange={handleChangeCheck}
            label="Mostrar días en sede"
            checked={values.diassede}
          />
        </Grid>

        <Grid item xs={4}>
          <Controls.Button
             text = {spinner
                      ? "Generar PDF"
                      : <Button disabled>
                            <CircularProgress size={12} style={{ marginRight: "5px" }} />
                            Obteniendo datos...
                        </Button>
                    }
            disabled={spinner ? false : true}
            onClick={ pdfdownolad }
          />
        </Grid>

      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
