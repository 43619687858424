import Axios from "axios";
import { muDate, isDate } from "helpers/helpers";
const BASE_URL = "/recaudacion";

export default {
  save(worker) {
    return new Promise(function (resolve, reject) {
      Axios.post(`${BASE_URL}`, format(worker))
        .then((r) => {
          let res = r;
          res.data = parse(r.data);
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  update(worker) {
    return new Promise(function (resolve, reject) {
      Axios.put(`${BASE_URL}/${worker.id}`, format(worker))
        .then((r) => {
          let res = r;
          res.data = parse(r.data);
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  find() {
    return new Promise(function (resolve, reject) {
      Axios.get(BASE_URL)
        .then((r) => {
          let res = r;
          res.data = r.data.map((e) => parse(e));
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  findByEstadoId(id) {
    return new Promise(function (resolve, reject) {
      Axios.get(`${BASE_URL}/estado/${id}`)
        .then((r) => {
          let res = r;
          res.data = r.data;
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  findByEstado() {
    return new Promise(function (resolve, reject) {
      Axios.get(`${BASE_URL}/estado`)
        .then((r) => {
          let res = r;
          res.data = r.data;
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  totales() {
    return new Promise(function (resolve, reject) {
      Axios.get(`${BASE_URL}/acumulado`)
        .then((r) => {
          let res = r;
          res.data = r.data;
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  reporte(data) {
    return new Promise(function (resolve, reject) {
      Axios.post(`${BASE_URL}/reporte`, formato(data))
        .then((r) => {
          let res = r;
          res.data = r.data;
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  compare(data) {
    return new Promise(function (resolve, reject) {
      Axios.post(`${BASE_URL}/compare`, formato1(data))
        .then((r) => {
          let res = r;
          res.data = r.data;
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  remove(id) {
  return new Promise(function(resolve, reject) {
      Axios.delete(`${BASE_URL}/${id}`).then((r)=> {
          let res = r;
          console.log(r.data)
//                res.data = parse(r.data);
//            resolve(res);
      }).catch(e=>reject(e));
  });
}
};

function formato (data) {
  return {
    from:isDate(data.from),
    //.toLocaleDateString('es-VE').split( '/' ).reverse( ).join( '-' ),
    to: isDate(data.to),
    //.toLocaleDateString('es-VE').split( '/' ).reverse( ).join( '-' ),
    estado: data.estado===''?null:data.estado,
    sede: data.sede===''?null:data.sede,
    dias: data.dias,
    diassede: data.diassede,
  };
};

function formato1 (data) {
  return {
    fecha: data.fecha.toLocaleDateString('es-VE').split( '/' ).reverse( ).join( '-' ),
    serial: data.serial,
  };
};

function format(data) {
  return {
//    id: data.id,
    sede: data.sede,
    canal: data.canal,
    recaudador: data.recaudador,
    divisa: data.divisa,
    denominacion: data.denominacion,
    serial: data.serial,
    retirado_por: data.retirado_por,
    fecha: isDate(data.fecha),
    //.toLocaleDateString('es-VE').split( '/' ).reverse( ).join( '-' ),
    turno: data.turno,
  };
}

/** Traduce la data desde backend al formato del front */

const parse = (data) => {
  return {
    id: data.id,
    sede: data.sede,
    canal: data.canal,
    divisa: data.divisa,
    nombre: data.nombreDivisa,
    nombreSede: data.nombreSede,
    denominacion: data.denominacion,
    recaudador: data.recaudador,
    serial: data.serial,
    retirado_por: data.retirado_por,
    fecha: muDate(data.fecha),
    turno: data.turno
  };
};
