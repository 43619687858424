// helpers nos ayuda con funciones caracteristicas

export function errorValues(data) {
	  return Object.values(data).join("\n");
}

export function onlyNumbers2(event) {
	var keynum;
	if (window.event) {
		keynum = event.keyCode;
	} else if(event.which) {
		keynum = event.which;
	}else{
		keynum = 0;
	}
	console.log(keynum)
	if(keynum===8 || keynum===0 || keynum===9) {
		return;
	}
	if (keynum<46 || keynum>57 || keynum===47) {
		event.preventDefault();
	}
}

export function onlyNumbers(event) {
	console.log((event.target.value))
	console.log(typeof(event.target.value))
	if ( typeof(event.target.value)!=='string' ) {
		console.log(event.target.value)
		return event.target.value;
	}
}

/*
* Exporta fechas sistema español
*/
export function toFecha(k) {
	let a = new Date(k.replace(/-/g,'\/'));
	return a.toLocaleDateString('es-VE');
};

/*
* Exporta fecha compatible picker materia ui
*/
export function muDate(fecha) {
    return fecha.replace(/-/g,'\/');
}

/*
* Exporta si el formato es tipo fecha
*/
export function isDate(data) {
    try{
        return data.toISOString().split('T')[0];
    }catch{
        if (data!=='') {
            return data.replace(/\//g,'-');
        } else {
            return '';
        }
    }
};
