import Axios from "axios";
const BASE_URL = "/tipovehiculo";


export default {
  save(worker) {
    return new Promise(function (resolve, reject) {
      Axios.post(`${BASE_URL}`, format(worker))
        .then((r) => {
          let res = r;
          res.data = parse(r.data);
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },


  update(worker) {
    return new Promise(function (resolve, reject) {
      Axios.put(`${BASE_URL}/${worker.id}`, format(worker))
        .then((r) => {
          let res = r;
          res.data = parse(r.data);
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  find(data) {
    return new Promise(function (resolve, reject) {
      Axios.get(BASE_URL)
        .then((r) => {
          let res = r;
          res.data = r.data.map((e) => parse(e));
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  findById(id) {
    return new Promise(function (resolve, reject) {
      Axios.get(`${BASE_URL}/${id}`)
        .then((r) => {
          let res = r;
          if (r.data.length > 0) {
            res.data = parse(r.data[0]);
          } else {
            res.data = {};
          }
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
};





function format(data) {
  return {
    id: data.id,
    nombre: data.name,
    estado: data.estado,
  };
}

/** Traduce la data desde backend al formato del front */

const parse = data =>  {
  return {
      id: data.id,
      name: data.nombre
  }
};

