import Axios from "axios";

const TOKEN_API  = "user_token";
const TOKEN_ROL  = "user_rol";
const TOKEN_ID   = "user_id";
const TOKEN_SEDE = "user_sede";

export function setToken(token) {
  localStorage.setItem(TOKEN_API, token);
}

export function getToken() {
  return localStorage.getItem(TOKEN_API);
}

export function getRol() {
  let payload = jwtDecode(localStorage.getItem(TOKEN_API));
  return Number(payload.payload.rol);
}

export function getSede() {
  let payload = jwtDecode(localStorage.getItem(TOKEN_API));
  return Number(payload.payload.sede);
}

export function getName() {
  let payload = jwtDecode(localStorage.getItem(TOKEN_API));
  return payload.payload.nombre;
}

export function getUserId() {
  let payload = jwtDecode(localStorage.getItem(TOKEN_API));
  return Number(payload.payload.id);
}

export function deleteToken() {
  localStorage.removeItem(TOKEN_API);
}

export function initAxiosInterceptors() {
  //axios.defaults.baseURL = 'backendurl';
  Axios.interceptors.request.use(function (config) {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    return config;
  });

  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        deleteToken();
        window.location = "/login";
      } else {
        return Promise.reject(error);
      }
    }
  );
}

export function jwtDecode(t) {
  let token = {};
  token.raw = t;
  token.header = JSON.parse(window.atob(t.split('.')[0]));
  token.payload = JSON.parse(window.atob(t.split('.')[1]));
  return (token)
}