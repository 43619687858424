import React, { Component } from 'react';
import { Form, Card, Col, Button, Modal } from 'react-bootstrap';
import { withRouter } from "react-router";
import userService from "servicios/usuarioService";
import "style/formularios.css";
class Usuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //user data
            id: 0,
            name: '',
            lastname: '',
            dni: '',
            email: '',
            isLock: false,
            isActive: true,
            dnitype: 'V',
            //view state
            formvalidated: false,
            showSearch: true
        };
        this.handleSubmit.bind(this);
        this.saveProcess.bind(this);
        this.componentDidMount.bind(this);
        this.handleClose.bind(this);
        
    }
    handleClose = () => {
        this.setState({ showSearch: false });
    };
    componentDidMount() {
        if (this.props.match && this.props.match.params.id){
            userService.findById(this.props.match.params.id).then(r=>{
                this.setState(r.data);
            })
        }
    }

    componentDidUpdate() {
        if ((!this.props.match || !this.props.match.params.id) && this.state.id){
            this.setState({
                //user data
                id: 0,
                name: '',
                lastname: '',
                dni: '',
                email: '',
                isLock: false,
                isActive: true,
                dnitype: 'V',
                //view state
                formvalidated: false,
                showSearch: true
            })
        }
    }
    

    useEffect() {
        alert(this.state.modificar);
    }

    handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ formvalidated: false });
        } else {
            this.setState({ formvalidated: true });
        }
        this.setState({ formvalidated: true });
        event.preventDefault();
        event.stopPropagation();
        this.saveProcess();
    };

    saveProcess() {
        this.setState({
            name: this.state.name.toUpperCase(),
            lastname: this.state.lastname.toUpperCase(),
            email: this.state.email.toLowerCase()
        });
        userService.save(this.state).then((r) => {
            this.setState(r.data);
        });
    }

    render() {
        return (
            <>
                <Form noValidate validated={this.state.formvalidated} onSubmit={this.handleSubmit}>
                    <Card>
                        <Card.Header className="card-header">Datos Personales</Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="name">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        value={this.state.name}
                                        onChange={(event) => { this.setState({ name: event.target.value }); }}
                                        required type="text" placeholder="Nombre" />
                                    <Form.Control.Feedback type="invalid">
                                        El nombre del usuario es obligatorio.
                        </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="lastname">
                                    <Form.Label>Apellido</Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        value={this.state.lastname}
                                        onChange={(event) => { this.setState({ lastname: event.target.value }); }}
                                        required type="text"
                                        placeholder="Apellido" />
                                    <Form.Control.Feedback type="invalid">
                                        El apellido del usuario es obligatorio.
                        </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs="4" md="2" controlId="dnitype">
                                    <Form.Label>Cédula</Form.Label>
                                    <Form.Control as="select"
                                        value={this.state.dnitype}
                                        onChange={(event) => { this.setState({ dnitype: `${event.target.value}` }); }}>
                                        <option>V</option>
                                        <option>E</option>
                                        <option>N/A</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xs="8" md="4" controlId="dni">
                                    <Form.Label> &nbsp;</Form.Label>
                                    <Form.Control
                                        value={this.state.dni}
                                        autoComplete="off"
                                        onChange={(event) => { this.setState({ dni: `${event.target.value}` }); }}
                                        required
                                        type="number"
                                        placeholder="Cédula" />
                                    <Form.Control.Feedback type="invalid">
                                        El número de cédula debe ser válido.
                            </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="title">
                                    <Form.Label>Título, Saludo</Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        value={this.state.title}
                                        onChange={(event) => { this.setState({ dni: `${event.target.value}` }); }}
                                        type="text"
                                        placeholder="Ejemplo: Sr., Sra., Ing., Lic." />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="email">
                                    <Form.Label>Correo Electrónico</Form.Label>
                                    <Form.Control
                                        value={this.state.email}
                                        autoComplete="off"
                                        onChange={(event) => { this.setState({ email: `${event.target.value}` }); }}
                                        required
                                        type="email"
                                        placeholder="ejemplo@ejemplo.com" />
                                    <Form.Control.Feedback type="invalid">
                                        Ingrese un email válido, ejemplo: ejemplo@ejemplo.com
                        </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="isLock">
                                    <Form.Check
                                        checked={this.state.isLock}
                                        onChange={(event) => { this.setState({ isLock: event.target.checked }); }}
                                        type='checkbox'
                                        label='Bloqueado' />
                                </Form.Group>
                                <Form.Group as={Col} controlId="isActive">
                                    <Form.Check
                                        checked={this.state.isActive}
                                        onChange={(event) => { this.setState({ isActive: event.target.checked }); }}
                                        type='checkbox'
                                        label='Activo' />
                                </Form.Group>
                            </Form.Row>

                        </Card.Body>
                    </Card>
                    <Form.Row className="align-items-center my-8">
                        <Col className="mx-8">
                            <span></span>
                        </Col>
                        <Col className="mx-8">
                            <Button type="submit" block>Guardar Cambios</Button>
                        </Col>
                        <Col className="mx-8">
                            <span></span>
                        </Col>
                    </Form.Row>

                </Form>
                <Modal
                    show={this.props.modificar && this.state.showSearch}
                    onHide={() => this.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Modal title</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        I will not close if you click outside me. Don't even try to press
                        escape key.
                </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                </Button>
                        <Button variant="primary">Understood</Button>
                    </Modal.Footer>
                </Modal>



            </>
        );
    }
}

export default withRouter(Usuario);