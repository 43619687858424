import React, { Component } from 'react';
import AddAsistencias from 'componentes/asistencia/addasistencias';
import ImagenLogo from "imagenes/identity-card.svg";
import "style/login.css";
import LoginNavbar from 'componentes/navbars/loginnavbar';
import { Form, Col, Row } from 'react-bootstrap';

class Ingreso extends Component {
    constructor(props){
        super(props);
        this.state = {
            style: 'display: block'
         /**  visible: 'block' */
        }
    }

    render() {
        return (
            <>
                <LoginNavbar visible={this.state.visible}></LoginNavbar>

                <Row className="mx-0 align-items-center" style={{height: "inherit",marginTop: "-82px"}}>
                    <Col md="6" className="d-none d-md-block">
                        <img src={ImagenLogo} alt="" className="Signup__img mx-auto" />          
                    </Col>
                    <Col md="4" sm="8" className="mx-auto">
                        <Form>
                            <Form.Row >
                                <h1 className="Form__titulo">Entrada / Salida</h1>
                            </Form.Row>
                            <Form.Row >
                            <AddAsistencias></AddAsistencias>
                            </Form.Row>
                            <Form.Row >
                            <Form.Label color="error">{this.state.errormessage}</Form.Label>
                            </Form.Row>
                        </Form>
                    </Col>
                </Row>


            </>
        );
    }
}

export default Ingreso;