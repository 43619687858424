/**
 * Personal service
 */
import Axios from "axios";
import { getUserId } from '../helpers/auth-helpers';
const BASE_URL = '/personal';

export default {
    save(worker){        
        return new Promise(function(resolve, reject) {
            Axios.post(`${BASE_URL}`,format(worker)).then((r)=> {
                let res = r;
                res.data =  parse(r.data);
                resolve(res);
            }).catch(e=>reject(e));
        });
    },
    update(worker){
        return new Promise(function(resolve, reject) {
            Axios.put(`${BASE_URL}/${worker.id}`,format(worker)).then((r)=> {                
                let res = r;
                res.data = parse(r.data);             
                resolve(res);
            }).catch(e=>{
                reject(e)});
        });

    },
    updatePassword(data){
        return new Promise(function(resolve, reject) {
            Axios.put(`${BASE_URL}/updatepassword/${getUserId()}`,
                {password: data.password, oldpassword: data.oldpassword})
            .then((r)=> {                
                let res = r;
                res.data = parse(r.data);             
                resolve(res);
            }).catch(e=>{
                reject(e)});
        });
    },
    
    find(data){
        return new Promise(function(resolve, reject) {
            Axios.get(BASE_URL).then((r)=> {
                let res = r;
                res.data = r.data.map(e=>parse(e)); 
                resolve(res);
            }).catch(e=>reject(e));
        });
    },

    findo(){
        return new Promise(function(resolve, reject) {
            Axios.get(BASE_URL).then((r)=> {
                let res = r;
                res.data = r.data.map(e=>parseo(e)); 
                resolve(res);
            }).catch(e=>reject(e));
        });
    },

    findBySede(id){
        return new Promise(function(resolve, reject) {
            Axios.get(`${BASE_URL}/sede/${id}`).then((r)=> {
                let res = r;
                res.data = r.data.map(e=>parse(e)); 
                resolve(res);
            }).catch(e=>reject(e));
        });
    },

    report(data){
        return new Promise(function(resolve, reject) {

            Axios.post(`${BASE_URL}/report`,
                {
                    nombre: data.name?data.name:null,
                    cedula: data.dni?data.dni:null,
                    email: data.email ? data.email : null,
                }
                ).then((r)=> {
                let res = r;
                res.data = r.data.map(e=>parse(e)); 
                resolve(res);
            }).catch(e=>reject(e));
        });
    },
    findById(id){
        return new Promise(function(resolve, reject) {
            Axios.get(`${BASE_URL}/${id}`).then((r)=> {
                let res = r;
                if (r.data.length>0){ 
                    res.data = parse(r.data[0]);                
                }else{
                    res.data = {};
                }
                resolve(res);
            }).catch(e=>reject(e));
        });
    },



}

function format(data){
    return {
        id: data.id,
        nombre: data.name,
        password: data.password,
        apellido: data.lastname,
        cedula: `${data.dnitype}${data.dni}`,
        telefono: data.phone,
        direccion: data.address,
        observaciones: data.observation,
        email: data.email,
        rol: data.role ? Number(data.role) : null,
        sede: data.sede? Number(data.sede):null,
        empresa: data.empresa? Number(data.empresa):null,
        sistema: "sistema",
        es_usuario_sistema: data.isSystemUser ? 1 : 0,
        bankid: Number(data.bankid),
        accountno: data.accountno,
        accounttype: Number(data.accounttype),
        isownaccount: data.isownaccount ? 1 : 0,
        accountdni: data.accountdni ? data.accountdni:null, 
        accountname: data.accountname ? data.accountname:null,
    }
} 

/** Traduce la data desde backend al formato del front */
function parse (data)  {
    var arr = {
        id: data.id,
        name : data.nombre.toUpperCase(),
        lastname: data.apellido.toUpperCase(),
        nombre: data.nombre.toUpperCase()+' '+data.apellido.toUpperCase(),
        email: data.email,
        dni: data.cedula? data.cedula.substring(1): '',
        isActive: true,
        dnitype: data.cedula? data.cedula.substring(0,1): 'V',
        observation: data.observaciones,
        phone: data.telefono,
        address: data.direccion,
        isSystemUser: data.es_usuario_sistema && data.es_usuario_sistema>0,
        username: data.email,
        password: data.es_usuario_sistema && data.es_usuario_sistema>0?"fakepassword":null,
        role: data.rol,
        sede: data.sede,
        empresa: data.empresa
    }
    if (data.cuenta!==undefined && data.cuenta!==null) {
        arr.bankid = data.cuenta.banco_id;
        arr.accountno = data.cuenta.accountno;
        arr.accounttype = data.cuenta.accounttype;
        arr.isownaccount = data.cuenta.isownaccount;
        arr.accountdni = data.cuenta.accountdni;
        arr.accountname = data.cuenta.accountname;
    }
    return arr;
}

function parseo(data) {
    var arr = {
        id: data.id,
        nombre: data.nombre.toUpperCase()+' '+data.apellido.toUpperCase(),
    }
    return arr;
}
