import React, { Component } from 'react';
import { Form, Col, Button} from 'react-bootstrap';
import { withRouter } from "react-router";
import empresaService from "servicios/empresaService";
import ProcesandoModal from 'componentes/comunes/ProcesandoModal';
import "style/searchview.css"
class EmpresaBuscar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //user data
            id: 0,
            nombre: '',
            rif: '',
            telefono: '',

            //view state
            formvalidated: false,
            showSearch: true,
            processing: false,
        };
        this.handleSubmit.bind(this);
    }

    handleSubmit = (event)=>{        
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            processing: true,
        });
        empresaService.report(this.state).then(r=>{
            this.setState({
                processing: false,
            });
            
            if (r.data.length===1){
                this.props.history.push(`/empresa/${r.data[0].id}`);
            }else if (r.data.length>1){
                this.props.history.push(`/empresa/reporte`,
                    {
                        reportdata: r.data, 
                        activeAcordeon: "X",
                        nombre: this.state.nombre,
                        rif: this.state.rif, 
                        telefono: this.state.telefono
                    }
                );
            }
        }).catch(()=>{
            this.setState({
                processing: false,
            });
        })
    }

    render() {
        return (
            <>
            <ProcesandoModal show={this.state.processing} />
            <Form onSubmit={this.handleSubmit} className="search-form mx-auto">
                <Form.Row >
                    <Form.Group as={Col} controlId="nombre">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            value={this.state.nombre}
                            onChange={(event) => { this.setState({ nombre: event.target.value }); }}
                            type="text" placeholder="Nombre" />
                    </Form.Group>
                </Form.Row>
                <Form.Row >
                    <Form.Group as={Col} controlId="rif">
                        <Form.Label>RIF</Form.Label>
                        <Form.Control
                            value={this.state.rif}
                            onChange={(event) => { this.setState({ rif: event.target.value }); }}
                            type="text" placeholder="RIF" />
                    </Form.Group>
                </Form.Row>
                <Form.Row >
                    <Form.Group as={Col} controlId="telefono">
                    <Form.Label>Teléfono</Form.Label>
                        <Form.Control
                                value={this.state.telefono} 
                                onChange={(event)=>{this.setState({telefono:`${event.target.value}`})}}
                                placeholder="" />
                    </Form.Group>
                </Form.Row>

                <Form.Row className="align-items-center my-8">
                    <Col className="mx-2">
                        <span></span>
                    </Col>
                    <Col className="mx-8">
                        <Button type="submit" block>Buscar</Button>
                    </Col>
                    <Col className="mx-2">
                        <span></span>
                    </Col>
                </Form.Row>
            </Form>
            </>
        );
    }

}
export default withRouter(EmpresaBuscar);