import React from "react";
import SedeTable from "./SedeTable";

function Sede() {
  return (
    <div>
      <SedeTable />
    </div>
  );
}

export default Sede;
