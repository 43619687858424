import React, { useState, useEffect } from "react";
import { Grid, makeStyles, createMuiTheme } from "@material-ui/core";
import { esES } from "@material-ui/core/locale";

//componentes
import { useForm, Form } from "componentes/useForm";
import Controls from "componentes/controls/Controls";

//servicios
import estadoService from "servicios/estadoService";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      "& .MuiInputBase-root": {
        padding: theme.spacing(0.5),
      },
      "& .MuiOutlinedInput-inputMultiline": {
        padding: theme.spacing(2),
      },
      "& .MuiFormControl-root": {
        width: "100%",
        margin: theme.spacing(0.5),
        padding: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
      "& > * + *": {
        marginTop: theme.spacing(0.5),
      },
    },
    pageContent: { margin: theme.spacing(1), padding: theme.spacing(1) },
    media: {
      height: 200,
    },
    paper: {
      paddingVertical: theme.spacing(1),
      textAlign: "left",
      color: theme.palette.text.secondary,
      paddingBottom: 6,
      paddingTop: 2,
      paddingLeft: 6,
      paddingRight: 6,
    },
    fontFecha: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      width: 200,
    },
    textarea: {
      width: "90%",
    },
    normalLong: {
      width: "25%",
    },
  }),
  esES
);

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 12,
  },
});

const initialFValues = {
  id: 0,
  nombre: "",
  estado: 0,
  activa: 1,
};

function SedeForm(props) {
  const classes = useStyles();
  const [estados, setEstados] = useState([]);
  const { addOrEdit, recordForEdit } = props;
  const [radios, setRadios] = useState('si');

  //validacion de formulario
  const validate = (fieldValues = values) => {
    Object.entries(fieldValues).forEach((entry) => {
      const [key, value] = entry;
      if (typeof value !== "number") {
        setValues({ ...values, [key]: value.toUpperCase() });
      } else {
        setValues({ ...values, [key]: value });
      }
    });
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Campo obligatorio";
    if ("estado" in fieldValues)
      temp.estado = fieldValues.estado.length !== 0 ? "" : "Campo obligatorio";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  async function getEstados() {
    try {
      let estadosDB = await estadoService.getAll();
      setEstados(estadosDB.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  const handleRadioChange = (event) => {
    setRadios(event.target.value);
    setValues({...values, activa: event.target.value==='si'?1:0 });
  };

  useEffect(() => {
    getEstados();
    if (recordForEdit !== null) {
      setValues({ ...recordForEdit });
      setRadios(recordForEdit.activa?'si':'no');
      console.table("valores edit=>", recordForEdit);
    }
  }, [recordForEdit, setValues]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={4}>
            <Controls.Input
              name="nombre"
              label="Nombre"
              value={values.nombre}
              onChange={handleInputChange}
              error={errors.nombre}
            />
          </Grid>
          <Grid item xs={4}>
            <Controls.Select
              name="estado"
              label="Estado"
              value={values.estado}
              onChange={handleInputChange}
              options={estados}
              campo={"nombre"}
              error={errors.estado}
            />
          </Grid>

          <Grid item xs={4}>
            <Controls.RadioGroup
              label="Activa"
              value={radios}
              margenes={{margin:5}}
              items={[{id: "si", title: "Si"},{id: "no", title: "No"}]}
              onChange={handleRadioChange}
              error={errors.activa}
            />
          </Grid>

          <Grid item xs={12}>
            <div>
              <Controls.Button type="submit" text="Enviar" />
              <Controls.Button
                text="Limpiar"
                color="default"
                onClick={resetForm}
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

export default SedeForm;
