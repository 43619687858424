import React,{ Component } from 'react';
import { Modal,Spinner } from 'react-bootstrap';
/**
 * Modal para mantener la ventana bloqueada mientras se termina una tarea
 */
class ProcesandoModal extends Component{
    render(){
        return (
        <Modal show={this.props.show} centered size="sm" backdrop="static">
        <Modal.Body className="text-center">
            { !this.props.ShowSpin ? 
                <Spinner id="loadin" 
                animation="border" 
                variant="primary" 
                role="status">
                </Spinner>
                :null}
            <div>
                <span>{this.props.message ? this.props.message :'Procesando...'}</span>
            </div>
            </Modal.Body>
        </Modal>
        );
    }
}
export default ProcesandoModal;