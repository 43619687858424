import React, { useState, useEffect } from "react";
import { getRol, getUserId, getSede } from "helpers/auth-helpers";
import { toFecha } from "helpers/helpers";
import RecaudacionForm from "./RecaudacionForm";

//material-ui
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
} from "@material-ui/core";
import Controls from "componentes/controls/Controls";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

import Notification from "componentes/controls/Notifications";
import ConfirmDialog from "componentes/ConfirmDialog";
import Popup from "componentes/controls/Popup";
import useTable from "componentes/controls/useTable";
import swal from "sweetalert";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
// SERVICIOS
import recaudacionService from 'servicios/recaudacionService';
import divisaService from 'servicios/divisaService';
import sedeService from 'servicios/sedeService';


const theme = createMuiTheme({
  palette: {
    secondary: {
      // This is green.A700 as hex.
      main: "#0FBA57",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  table: {
    fontSize: 12,
    marginTop: theme.spacing(1),
    tableLayout: "fix",
    //tableLayout: 'fixed',
    "& thead th": {
      fontWeight: "600",
      color: "#4A4A4A", //theme.palette.primary.light,
      backgroundColor: "#b0bec5", //backgroud del encabezado de la tabla
    },
    "& tbody td": {
      fontWeight: "100",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fff",
      cursor: "pointer",
    },
  },

  pageContent: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    overflowX: "auto",
    display: "fixed",
    width: "100%",
  },
  searchInput: {
    width: "30%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  tableCell: {
    fontSize: 11,
    size: "small",
    padding: theme.spacing(1),
  },
  tableCell2: {
    fontSize: 11,
    size: "small",
    paddingRight: theme.spacing(1),
    align: "right",
  },
  tableCellIcon: {
    fontSize: 11,
    size: "small",
  },
  spanLeft: {
    margingLeft: theme.spacing(8),
  },
}));

const headCells = [
  { id: "id", label: "#", disableSorting: false },
  {id: "fecha", label: "fecha", disableSorting: false },
  {
    id: "canal",
    label: "Canal",
    disableSorting: false,
    align: "left",
  },
  {
    id: "divisa",
    label: "Divisa",
    disableSorting: false,
    align: "left",
  },
  {
    id: "denominacion",
    label: "Denominación",
    disableSorting: false,
    align: "right",
  },
  {
    id: "sede",
    label: "Sede",
    disableSorting: false,
    align: "left",
  },
  { id: "actions", label: "Acciones", disableSorting: true },
];

const headCellsNoActions = [
  { id: "id", label: "#", disableSorting: false },
  { id: "fecha", label: "fecha", disableSorting: false },
  {
    id: "nombre",
    label: "nombre",
    disableSorting: false,
    align: "left",
  },
  { id: "actions", label: "Acciones", disableSorting: true },
];

const Recaudacion = () => {
  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [rol, setRol] = useState([]);
  const [sede, setSede] = useState([]);
  const [recaudacion, setRecaudacion] = useState([]);
  const [divisas, setDivisas] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [cargador, setCargador] = useState('Cargando datos...');
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(
    recaudacion,
    headCells,
    filterFn
  );

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
        return items.filter(
          (x) =>
            x.nombre.toLowerCase().includes(target.value) ||
            x.serial.toLowerCase().includes(target.value) ||
            x.nombreSede.toLowerCase().includes(target.value)
        );
      },
    });
  };

  //funcion para borrar
  const onDelete = (estado) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    //llamar al servicio para borrar el registro
    try {
      //borrar registro
      recaudacionService.remove(estado.id);
      getRecaudacion();
      console.log("borrando estado", estado.id);
      setNotify({
        isOpen: true,
        message: "Registro eliminado con éxito!!!",
        type: "error",
      });
    } catch (e) {
      console.log("error =>", e);
    }
    getRecaudacion();
  };

  //manejo del envio del formulario add or edit
  const addOrEdit = (recaudacion, resetForm) => {
    setNotify({
      isOpen: true,
      message: "El registro esta siendo procesado",
      type: "warning",
    });
    if (recaudacion.id === null) {
      try {
        let proc = (r) => {
          if (r.data.id) {
            setNotify({
              isOpen: true,
              message: "Registro ingresado con éxito!!!",
              type: "success",
              autoHideDuration: 5000,
            });
            //            mensaje();
          }
          //          setProcessing(false);
        };
        let procError = (e) => {
          console.log(e)
          setNotify({
            isOpen: true,
            message: e,
            type: "warning",
            autoHideDuration: 5000,
          });
          swal({ text: e, icon: "warning" });
        };

        recaudacionService.save(recaudacion).then(proc).catch(procError);
        getRecaudacion();
        console.log("valores  ingresados ", recaudacion);
      } catch (e) {
        console.log("error =>", e);
      }
    } else {
      //llamar servicio para el update put /categorias/:id
      try {
        let proc = (r) => {
          if (r.data.id) {
            setNotify({
              isOpen: true,
              message: "Registro actualizado con éxito!!!",
              type: "success",
              autoHideDuration: 5000,
            });
            //            mensaje();
          }
        };
        let procError = (e) => {
          setNotify({
            isOpen: true,
            message: e,
            type: "warning",
            autoHideDuration: 5000,
          });
          swal({ text: e, icon: "warning" });
        };
        recaudacionService.update(recaudacion).then(proc).catch(procError);
        getRecaudacion();
      } catch (e) {
        console.log("error =>", e);
      }
    }
    resetForm();
    getRecaudacion();
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  //enviar registro al Popup para edicion
  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  async function getRecaudacion() {
    try {
      let items = await recaudacionService.find();
      setRecaudacion(items.data);
      if (items.data.length===0) {
        setCargador('No hay datos que mostrar');
      }
    } catch (err) {
      setRecaudacion([]);
      setCargador('Se produjo un error. Favor vuela a intentarlo!');
      console.log("Error =>", err);
    }
  }

  async function getDivisas() {
    try {
      let items = await divisaService.find();
      setDivisas(items.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  async function getSedes() {
    try {
      let items = await sedeService.find();
      setSedes(items.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  useEffect(() => {
    getRecaudacion();
    getDivisas();
    getSedes();
    setSede(`${getSede()}`);
    setRol(`${getRol()}`);
  }, []);

  return (
    <>
      <Controls.PageHeader
        title="Recaudación"
        icon={<AccountBalanceIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Controls.Button
            text="Nuevo"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(null);
            }}
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody className={classes.pageContent}>
            {recordsAfterPagingAndSorting().length > 0 ? (
              recordsAfterPagingAndSorting().map((item, i) => (
                <TableRow key={item.serial}>
                  <TableCell className={classes.tableCell}>{i + 1}</TableCell>
                  <TableCell className={classes.tableCell}>
                    {toFecha(item.fecha)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item.canal}
                  </TableCell>
                  <TableCell className={classes.tableCell2}>
                    {item.nombre}
                  </TableCell>
                  <TableCell className={classes.tableCell2}>
                    {item.denominacion}
                    <div style={{ display: item.serial ? "none" : "block" }}>
                      info
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell2}>
                    {item.nombreSede}
                  </TableCell>

                  {rol.includes("1") ? (
                    <TableCell className={classes.tableCell}>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        {" "}
                        <EditOutlinedIcon
                          className={classes.tableCellIcon}
                        />{" "}
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              "¿ está seguro de querer borrar el registro ?",
                            subTitle: "esta operación es definitiva",
                            onConfirm: () => {
                              onDelete(item);
                            },
                          });
                        }}
                      >
                        {" "}
                        <CloseIcon className={classes.tableCellIcon} />{" "}
                      </Controls.ActionButton>
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableCell}>
                      No hay acciones disponibles
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>{cargador}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="Recaudacion"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setRecordForEdit={recordForEdit}
      >
        <RecaudacionForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          divisas={divisas}
          sedes={sedes}
          sede={sede}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Recaudacion;
