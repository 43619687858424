import React, { useState, useEffect } from "react";
import { getRol, getUserId } from "helpers/auth-helpers";
import DivisaForm from "./DivisaForm";

//material-ui
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
} from "@material-ui/core";
import Controls from "componentes/controls/Controls";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import CommuteIcon from "@material-ui/icons/Commute";

import Notification from "componentes/controls/Notifications";
import ConfirmDialog from "componentes/ConfirmDialog";
import Popup from "componentes/controls/Popup";
import useTable from "componentes/controls/useTable";
import swal from "sweetalert";
import divisaService from "../../servicios/divisaService";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
  palette: {
    secondary: {
      // This is green.A700 as hex.
      main: "#0FBA57",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  table: {
    fontSize: 12,
    marginTop: theme.spacing(1),
    tableLayout: "fix",
    //tableLayout: 'fixed',
    "& thead th": {
      fontWeight: "600",
      color: "#4A4A4A", //theme.palette.primary.light,
      backgroundColor: "#b0bec5", //backgroud del encabezado de la tabla
    },
    "& tbody td": {
      fontWeight: "100",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fff",
      cursor: "pointer",
    },
  },

  pageContent: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    overflowX: "auto",
    display: "fixed",
    width: "100%",
  },
  searchInput: {
    width: "30%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  tableCell: {
    fontSize: 11,
    size: "small",
    padding: theme.spacing(1),
  },
  tableCell2: {
    fontSize: 11,
    size: "small",
    paddingRight: theme.spacing(1),
    align: "right",
  },
  tableCellIcon: {
    fontSize: 11,
    size: "small",
  },
  spanLeft: {
    margingLeft: theme.spacing(8),
  },
}));

const headCells = [
  { id: "id", label: "Id", disableSorting: false },
  {
    id: "nombre",
    label: "Nombre",
    disableSorting: false,
    align: "left",
  },
  { id: "actions", label: "Acciones", disableSorting: true },
];

const headCellsNoActions = [
  { id: "id", label: "Id", disableSorting: false },
  {
    id: "nombre",
    label: "Nombre",
    disableSorting: false,
    align: "left",
  },
  { id: "actions", label: "Acciones", disableSorting: true },
];

const DivisaTable = () => {
  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [rol, setRol] = useState([]);
  const [sede, setSede] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(
    sede,
    rol.includes("1") ? headCells : headCellsNoActions,
    filterFn
  );

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter(
            (x) =>
              x.nombre.toLowerCase().includes(target.value)
          );
      },
    });
  };

  //funcion para borrar
  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    //llamar al servicio para borrar el registro
    try {
      //borrar registro
      divisaService.remove(id);
      getAllSedes();
      console.log("borrando sede", id);
      setNotify({
        isOpen: true,
        message: "Registro eliminado con éxito!!!",
        type: "error",
      });
    } catch (e) {
      console.log("error =>", e);
    }
    getAllSedes();
  };

  //manejo del envio del formulario add or edit
  const addOrEdit = (sede, resetForm) => {
    setNotify({
      isOpen: true,
      message: "El registro esta siendo procesado",
      type: "warning",
    });
    if (sede.id === 0) {
      try {
        let proc = (r) => {
          if (r.data.id) {
            setNotify({
              isOpen: true,
              message: "Registro ingresado con éxito!!!",
              type: "success",
              autoHideDuration: 5000,
            });
            //            mensaje();
          }
          //          setProcessing(false);
        };
        let procError = (e) => {
          setNotify({
            isOpen: true,
            message: e,
            type: "warning",
            autoHideDuration: 5000,
          });
          swal({ text: e, icon: "warning" });
        };
        console.log("valores  ingresados ", sede);
        divisaService.save(sede).then(proc).catch(procError);
        getAllSedes();
      } catch (e) {
        console.log("error =>", e);
      }
    } else {
      //llamar servicio para el update put /categorias/:id
      try {
        let proc = (r) => {
          if (r.data.id) {
            setNotify({
              isOpen: true,
              message: "Registro actualizado con éxito!!!",
              type: "success",
              autoHideDuration: 5000,
            });
            //            mensaje();
          }
        };
        let procError = (e) => {
          setNotify({
            isOpen: true,
            message: e,
            type: "warning",
            autoHideDuration: 5000,
          });
          swal({ text: e, icon: "warning" });
        };
        console.log("sede =>", sede);
        divisaService.update(sede).then(proc).catch(procError);
        getAllSedes();
        /*setNotify({
          isOpen: true,
          message: "Registro actualizado con éxito!!!",
          type: "success",
        });*/
        console.log("valores  editado", sede);
      } catch (e) {
        console.log("error =>", e);
      }
    }
    resetForm();
    getAllSedes();
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  //enviar registro al Popup para edicion
  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  async function getAllSedes() {
    try {
      let divisas = await divisaService.find();
      setSede(divisas.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  useEffect(() => {
    getAllSedes();
    setRol(`${getRol()}`);
  }, []);

  return (
    <>
      <Controls.PageHeader title="Divisas" icon={<MonetizationOnIcon fontSize="large" />} />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar divisa"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          {rol.includes("1") ? (
            <Controls.Button
              text="Nuevo"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          ) : (
            ""
          )}
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody className={classes.pageContent}>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.tableCell}>{item.id}</TableCell>
                <TableCell className={classes.tableCell}>
                  {item.nombre}
                </TableCell>
                {rol.includes("1") ? (
                  <TableCell className={classes.tableCell}>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        openInPopup(item);
                      }}
                    >
                      {" "}
                      <EditOutlinedIcon
                        className={classes.tableCellIcon}
                      />{" "}
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "¿ está seguro de querer borrar el registro ?",
                          subTitle: "esta operación es definitiva",
                          onConfirm: () => {
                            onDelete(item.id);
                          },
                        });
                      }}
                    >
                      {" "}
                      <CloseIcon className={classes.tableCellIcon} />{" "}
                    </Controls.ActionButton>
                  </TableCell>
                ) : (
                  "Loading..."
                )}
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="Divisa"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setRecordForEdit={recordForEdit}
      >
        <DivisaForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default DivisaTable;
