import React, { useState, useEffect } from "react";

//servicios
import personalService from "servicios/personalService";

import { getRol, getUserId } from "helpers/auth-helpers";
import { useParams } from "react-router-dom";
import { errorValues } from "../../helpers/helpers";

//import components
import useTable from "componentes/controls/useTable";
import PersonalForm from "./PersonalForm";
import Controls from "componentes/controls/Controls";
import Popup from "componentes/controls/Popup";
import Notification from "componentes/controls/Notifications";
import ConfirmDialog from "componentes/ConfirmDialog";
import swal from "sweetalert";

//material-ui
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";

//icons
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    overflowX: "auto",
    display: "fixed",
    width: "100%",
  },
  searchInput: {
    width: "30%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  tableCell: {
    fontSize: 11,
    size: "small",
    padding: theme.spacing(1),
  },
  tableCellIcon: {
    fontSize: 11,
    size: "small",
  },
}));

const headCells = [
  { id: "cedula", label: "Cédula", disableSorting: false },
  { id: "nombre", label: "Nombre", disableSorting: false },
  { id: "email", label: "Email", disableSorting: false },
  { id: "actions", label: "Acciones", disableSorting: true },
];

const headCellsNoActions = [
  { id: "cedula", label: "Cédula", disableSorting: false },
  { id: "nombre", label: "Nombre", disableSorting: false },
  { id: "email", label: "Email", disableSorting: false },
  { id: "actions", label: "Acciones", disableSorting: true },
];

function Personal2() {
  let { id } = useParams();
  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [personal, setPersonal] = useState([]);
  const [rol, setRol] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(
    personal,
    rol.includes("1") ? headCells : headCellsNoActions,
    filterFn
  );

  const mensaje = () => {
    swal({ text: "Registro Exitoso", icon: "success" });
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter(
            (x) =>
              x.nombre.toLowerCase().includes(target.value)
          );
      },
    });
  };

  //manejo del envio del formulario add or edit
  const addOrEdit = (vehiculo, resetForm) => {
    if (vehiculo.id === 0) {
      try {
        let proc = (r) => {
          if (r.data.id) {
            setNotify({
              isOpen: true,
              message: "Registro ingresado con éxito!!!",
              type: "success",
            });
            mensaje();
          }
//          setProcessing(false);
        };
        let procError = (e) => {
//          console.log(e)
            setNotify({
              isOpen: true,
              message: e,
              type: "warning",
            });
            swal({ text: e, icon: "warning" });
        };
        
        personalService.save(vehiculo).then(proc).catch(procError);
//        personalService.create(vehiculo);
        //TODO: Verificar el error si el vehiculo existe
        getAllPwesoal();
        console.log("valores del vehiculo ingresado", vehiculo);
      } catch (e) {
        console.log("error =>", e);
      }
    } else {
      //llamar servicio para el update put /categorias/:id
      try {
        personalService.update(vehiculo);
        getAllPwesoal();
        setNotify({
          isOpen: true,
          message: "Registro actualizado con éxito!!!",
          type: "success",
        });
        console.log("valores del vehiculo editado", vehiculo);
      } catch (e) {
        console.log("error =>", e);
      }
    }
    resetForm();
    getAllPwesoal();
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  //enviar registro al Popup para edicion
  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  //funcion para borrar
  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    //llamar al servicio para borrar el registro
    try {
      //borrar registro
      personalService.remove(id);
      getAllPwesoal();
      //console.log("borrando vehiculo", id);
      setNotify({
        isOpen: true,
        message: "Registro eliminado con éxito!!!",
        type: "error",
      });
    } catch (e) {
      console.log("error =>", e);
    }
    getAllPwesoal();
  };
  //funcion para mostrar los personal
  async function getAllPwesoal() {
    try {
      let personal = await personalService.find();
      console.log("personal =>", personal.data);
      setPersonal(personal.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  useEffect(() => {
    getAllPwesoal();
    setRol(`${getRol()}`);
  }, []);

  return (
    <>
      <Controls.PageHeader
        title="Personal"
        icon={<PeopleAltIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar personal"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          {rol.includes("1") ? (
            <Controls.Button
              text="Nuevo"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          ) : (
            ""
          )}
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody className={classes.pageContent}>
            {recordsAfterPagingAndSorting().length>0 ? recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.tableCell}>
                {item.dnitype}{item.dni}
                </TableCell>
                <TableCell className={classes.tableCell}>
                {`${item.name} ${item.lastname}`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.email}
                </TableCell>
                {rol.includes("1") ? (
                  <TableCell className={classes.tableCell}>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        openInPopup(item);
                      }}
                    >
                      {" "}
                      <EditOutlinedIcon
                        className={classes.tableCellIcon}
                      />{" "}
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "¿ está seguro de querer borrar el registro ?",
                          subTitle: "esta operación es definitiva",
                          onConfirm: () => {
                            onDelete(item.id);
                          },
                        });
                      }}
                    >
                      {" "}
                      <CloseIcon className={classes.tableCellIcon} />{" "}
                    </Controls.ActionButton>
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            )):('Cargando datos...')}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="Personal"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setRecordForEdit={recordForEdit}
      >
        <PersonalForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default Personal2;
