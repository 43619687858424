import React from 'react';
import EstadoTable from "./EstadoTable";

function Estado() {
    return (
        <div>
           <EstadoTable />
        </div>
    )
}

export default Estado
