import 'date-fns';
import React, { Fragment } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { esES } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

function Pickers(props) {
  const { name, label, value,Tam, margin="normal", variant="outlined", format = "dd/MM/yyyy", error = null, onChange, ...other } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{ width: Tam ,margin:"4px"}}
          disableToolbar
          variant={variant}
          inputVariant="outlined"
          format={format}
          margin={margin}
          id={name}
          name={name}
          label={label}
          value={value}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          {...other}
          esES
        />
    </MuiPickersUtilsProvider>
  );
}
export default Pickers;
