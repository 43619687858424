import React, { useEffect, useState } from "react";
import {useInterval} from 'componentes/comunes/useInterval';
import Notification from "componentes/controls/Notifications";

//Material-ui
import { DataGrid } from "@material-ui/data-grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";

//axios
import Axios from "axios";

import servicio from "../../servicios/recaudacionService";

//user
import { getRol, getUserId } from "helpers/auth-helpers";
import PieChart from "componentes/graficos/PieChart";
import LineChart from "componentes/graficos/LineChart";

//Estilos
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 200,
    maxWidth: 350,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const [expanded, setExpanded] = useState([{Y: false, C: false, M: false, G: false, B: false}]);
  const [peajes, setPeajes] = useState([]);
  const [totales, setTotales] = useState([]);
  const [yaracuy, setYaracuy] = useState([]);
  const [guarico, setGuarico] = useState([]);
  const [cargador, setCargador] = useState('Cargando datos...');
  const [cargadort, setCargadort] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleExpandClick = (val) => {
    setExpanded({...expanded, [val]: !expanded[val]});
  };

  const handleExpandClick2 = () => {
    setExpanded(!expanded);
  };

  const pie = (data) => {
    return {
      type: data.type,
      value: parseFloat(data.value)
    }
  }

  const line = (data) => {
    return {
      dia: data.dia,
      total: parseFloat(data.total)
    }
  }

  const dataLine = [
    {
      dia: "Lunes",
      total: 120,
    },
    {
      dia: "Martes",
      total: 100,
    },
    {
      dia: "Miercoles",
      total: 180,
    },
    {
      dia: "Jueves",
      total: 80,
    },
    {
      dia: "Viernes",
      total: 160,
    },
    {
      dia: "Sabado",
      total: 70,
    },
    {
      dia: "Domingo",
      total: 50,
    },
  ];

  let delay = 120000;

  //funcion para mostrar las categorias
  async function mostrarPeajes() {
    try {
       let category = await servicio.findByEstado();
       let datos = category.data;//.map((e) => pie(e));
       setPeajes(datos)
    } catch (err) {
      setCargador(err.message);
      console.log("Error =>", err);
    }
  }

  async function mostrarTotal() {
    try {
       let category = await servicio.totales();
       let datos = category.data; //.map((e) => line(e));
       setTotales(datos);
    } catch (err) {
      console.log(err.message)
      setCargadort(err.message);
      console.log("Error =>", err);
    }
  }

  useEffect(() => {
    if (getRol()<3) {
      mostrarTotal();
      mostrarPeajes();
    }
  }, []);

  useInterval(() => {
    if (getRol()<3) {
      setNotify({
        isOpen: true,
        message: "Actualizando información!!!",
        type: "warning",
      });
      mostrarTotal();
      mostrarPeajes();
    }
  }, delay);

  const today = new Date();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const pred = {
    tot: "Totales ",
    msg: " últimos 7 días",
  }

  return (
    <>
      <Table>
        <Box width="100%">
          <h4>{getRol()<3 ? "Resumen" : "Bienvenido"}</h4>
        </Box>
      </Table>
      <Grid container spacing={3}>
      {getRol() < 3 ? (
        <Grid item xs={6}>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  T
                </Avatar>
              }
              action={
                <IconButton>
                  <MoreVertIcon onClick={() => handleExpandClick('T')} />
                </IconButton>
              }
              title={pred.tot + pred.msg}
              subheader={today.toLocaleDateString("es-ES", options)}
            />
            <CardMedia
              className={classes.media}
              image="/static/images/cards/paella.jpg"
              title="xtreme"
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary"></Typography>
            </CardContent>
            {cargadort === null && totales.length===0 ?'Cargando datos...': totales.length===0? cargadort : <LineChart dataLine={totales} />}
          </Card>
        </Grid>
        ):("")}
      { peajes.length > 0 ? peajes.map((item) => (
        <Grid item xs={6}>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  {item.name.charAt(0)}
                </Avatar>
              }
              action={
                <IconButton>
                  <MoreVertIcon onClick={() => handleExpandClick(item.name.charAt(0))} />
                </IconButton>
              }
              title={item.name + pred.msg}
              subheader={today.toLocaleDateString("es-ES", options)}
            />
            <CardMedia
              className={classes.media}
              image="/static/images/cards/paella.jpg"
              title="xtreme"
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary"></Typography>
            </CardContent>
            {expanded[item.name.charAt(0)] ? <PieChart dataPie={item.items}/>: null}
            {!expanded[item.name.charAt(0)] ? <LineChart dataLine={item.linea} />: null}
          </Card>
        </Grid>
        )): getRol()<3 ? <p>{cargador}</p> : ""}

      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
