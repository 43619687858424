import React, { Component } from 'react';
import { Form, Col, Button} from 'react-bootstrap';
import { withRouter } from "react-router";
import personalService from "servicios/personalService";
import ProcesandoModal from 'componentes/comunes/ProcesandoModal';
import "style/searchview.css"
class PersonalBuscar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //user data
            name: '',
            dni: '',
            email: '',

            //view state
            formvalidated: false,
            showSearch: true,
            processing: false,
        };
        this.handleSubmit.bind(this);
    }

    
    

    handleSubmit = (event)=>{        
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            processing: true,
        });
        personalService.report(this.state).then(r=>{
            this.setState({
                processing: false,
            });
            
            if (r.data.length===1){
                this.props.history.push(`/personal/${r.data[0].id}`);
            }else if (r.data.length>1){
                this.props.history.push(`/personal/reporte`,
                    {
                        reportdata: r.data, 
                        activeAcordeon: "X",
                        name: this.state.name,
                        dni: this.state.dni, 
                        email: this.state.email
                    }
                );
            }
        }).catch(()=>{
            this.setState({
                processing: false,
            });
        })
    }

    render() {
        return (
            <>
            <ProcesandoModal show={this.state.processing} />
            <Form onSubmit={this.handleSubmit} className="search-form mx-auto">
                <Form.Row >
                    <Form.Group as={Col} controlId="name">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            value={this.state.name}
                            onChange={(event) => { this.setState({ name: event.target.value }); }}
                            type="text" placeholder="Nombre" />
                    </Form.Group>
                </Form.Row>
                <Form.Row >
                    <Form.Group as={Col} controlId="dni">
                        <Form.Label>Cédula</Form.Label>
                        <Form.Control
                            value={this.state.dni}
                            onChange={(event) => { this.setState({ dni: event.target.value }); }}
                            type="numeric" placeholder="Cédula" />
                    </Form.Group>
                </Form.Row>
                <Form.Row >
                    <Form.Group as={Col} controlId="email">
                    <Form.Label>Correo Electrónico</Form.Label>
                        <Form.Control
                                value={this.state.email} 
                                onChange={(event)=>{this.setState({email:`${event.target.value}`})}}
                                placeholder="ejemplo@ejemplo.com" />
                    </Form.Group>
                </Form.Row>

                <Form.Row className="align-items-center my-8">
                    <Col className="mx-2">
                        <span></span>
                    </Col>
                    <Col className="mx-8">
                        <Button type="submit" block>Buscar</Button>
                    </Col>
                    <Col className="mx-2">
                        <span></span>
                    </Col>
                </Form.Row>
            </Form>
            </>
        );
    }

}
export default withRouter(PersonalBuscar);