import React from 'react';
import { Pie } from '@ant-design/charts';

function PieChart(props) {
/*
    const dataPie = [
      {
        type: "La Entrada",
        value: 130737,
      },
      {
        type: "Guacara",
        value: 230737,
      },
    ];
*/

    const configPie = {
      appendPadding: 10,
      data: props.dataPie,
      angleField: "value",
      colorField: "type",
      height: 360,
      radius: 0.8,
      label: {
        type: "inner",
        offset: "0.5",
        content: "{name} {percentage}",
        style: {
          fill: "#140005",
          fontSize: 14,
          textAlign: "center",
        },
      },
    };

    return <Pie {...configPie} style={{ backgroundColor: "#f5f5f5" }} />;
}

export default PieChart
