import React from 'react';
import DivisaTable from './DivisaTable';

function Divisa() {
    return (
        <div>
            <DivisaTable />
        </div>
    )
}

export default Divisa
