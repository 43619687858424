import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © "}
      <Link color="inherit" href="">
        Sistemas Tecnológicos ARCORP c.a. - By
        arcorp developers
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "2vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    position: "fixed",
    padding: theme.spacing(1, 1),
    //paddingBottom: "0.5rem",
    marginTop: "auto",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}));

export const FooterC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">
        <footer className={classes.footer}>
          <Container maxWidth="sm">
            <small>
              <Typography variant="body2">
                
                <Copyright />
              </Typography>
            </small>
          </Container>
        </footer>
      </Container>
    </div>
  );
};

export default FooterC;
