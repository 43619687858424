import React from "react";
import { Button as MuiButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  label: {
    textTransform: "none",
  },
}));

export default function Button(props) {
    const { text, size, color, variant, onClick, ...other } = props;
    const classes = useStyles();
  return (
    <MuiButton
      variant={variant || "contained"}
      size={size || "large"}
      color={color || "primary"}
      onClick={onClick} classes={{root:classes.root, label:classes.label}}
      {...other}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      classes={{root:classes.root,label:classes.label}}
    >
      {text}
    </MuiButton>
  );
}
