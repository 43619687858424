/**
 * User service
 */

export default {
    save(user){        
        return new Promise(function(resolve, reject) {
            setTimeout(function() {
                resolve({data: user});
            }, 1000);
        });
    },
    update(user){
        return new Promise(function(resolve, reject) {
            setTimeout(function() {
                resolve({data: user});
            }, 1000);
        } );
    },

}