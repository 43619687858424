import React, { Component } from "react";
import { Form, Col, Button, Accordion, Card } from "react-bootstrap";
import { withRouter } from "react-router";
import personalService from "servicios/personalService";
import rolService from "servicios/rolservice";
import sedeService from "servicios/sedeService";
import empresaService from "servicios/empresaService";
import bancosService from "servicios/bancosService";
import ProcesandoModal from "componentes/comunes/ProcesandoModal";
import ErrorModal from "componentes/comunes/ErrorModal";
import InfoModal from "componentes/comunes/InfoModal";
import { getRol, getSede } from "helpers/auth-helpers";
import "style/formularios.css";
class Personal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //user data
      id: 0,
      name: "",
      lastname: "",
      dni: "",
      isActive: true,
      dnitype: "V",
      observation: "",
      phone: "",
      address: "",
      email: "",

      job: -1,
      isSystemUser: false,
      isPasswordSet: false,
      username: "",
      password: "",
      password2: "",
      role: 0,
      empresa: 0,

      bankid: 0,
      accounttype: "1",
      accountno: "",
      isownaccount: true,
      accountdni: "",
      accountname: "",

      //view
      banks: [],
      jobs: [
        { id: 1, name: "Supervidor" },
        { id: 2, name: "Administración" },
        { id: 3, name: "Operaciones" },
        { id: 4, name: "Recaudador" },
        { id: 5, name: "Sistemas" },
      ],
      rolelist: [],
      sedelist: [],
      empresalist: [],
      //view state
      formvalidated: false,
      showSearch: true,
      processing: false,
      showError: false,
      errorMsg: "",
      infoMsg: "",
      showInfo: false,
      sede:getRol()===1 ? 0: getSede(),
      activo:getRol()===1 ? false: true,
      activoAll:getRol()===5 ? true: false,

    };
    this.handleSubmit.bind(this);
    this.saveProcess.bind(this);
    this.componentDidMount.bind(this);

    }

  componentDidMount() {
    if (this.state.banks.length <= 0) {
      bancosService.find().then((r) => {
        let list = r.data;
        list.unshift({ id: 0, name: "Seleccione un banco..." });
        this.setState({ banks: list });
      });
    }
    if (this.state.rolelist.length <= 0) {
      rolService.find().then((r) => {
        let list = r.data;
        list.unshift({ id: 0, name: "Seleccione un rol..." });
        this.setState({ rolelist: list });
      });
    }
    if (this.state.sedelist.length <= 0) {
      sedeService.find().then((r) => {
        let list = r.data;
        list.unshift({ id: 0, name: "Seleccione una sede..." });
        this.setState({ sedelist: list });
      });
    }
    if (this.state.empresalist.length <= 0) {
      empresaService.find().then((r) => {
        let list = r.data;
        list.unshift({ id: 0, nombre: "Seleccione una empresa..." });
        this.setState({ empresalist: list });
      });
    }
    if (this.props.match && this.props.match.params.id) {
      if (!this.state.processing) {
        this.setState({
          processing: true,
        });
      }
      personalService
        .findById(this.props.match.params.id)
        .then((r) => {
          let data = r.data;
          data.processing = false;
          data.isPasswordSet = data.isSystemUser;
          this.setState(data);
        })
        .catch((e) => {
          this.setState({
            processing: false,
          });
          this.props.history.push(`/personal`);
        });
    }
  }

  componentDidUpdate() {
    if ((!this.props.match || !this.props.match.params.id) && this.state.id) {
      this.setState({
        //user data//user data
        id: 0,
        name: "",
        lastname: "",
        dni: "",
        isActive: true,
        dnitype: "V",
        observation: "",
        phone: "",
        address: "",
        email: "",

        job: -1,
        isSystemUser: false,
        isPasswordSet: false,
        username: "",
        password: "",
        role: 0,
        sede: 0,
        empresa: 0,

        bankid: 0,
        accounttype: 0,
        accountno: "",
        isownaccount: true,
        accountdni: "",
        accountname: "",
  
      });
    }
  }

  componentWillUnmount() {}

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      this.setState({ formvalidated: false });
    } else {
      this.setState({ formvalidated: true });
      this.saveProcess();
    }
    this.setState({ formvalidated: true });
  };

  saveProcess() {
    this.setState({
      processing: true,
    });

    let proc = (r) => {
      let data = {};
      if (this.state.id) {
        data = r.data;
        data.showInfo = true;
        data.infoMsg = "El registro se guardó satisfactoriamente.";
      }
      data.processing = false;
      this.setState(data);
      if (!this.state.id) {
        this.props.history.push(`/personal/${r.data.id}`);
      }
    };

    let procError = (r) => {
      if (r.response && r.response.data && r.response.data.message) {
        this.setState({
          processing: false,
          showError: true,
          errorMsg: r.response.data.message,
        });
      } else {
        this.setState({
          processing: false,
          showError: true,
          errorMsg: "Error guardando el registro.",
        });
      }
    };

    if (this.state.id && this.state.id > 0) {
      personalService.update(this.state).then(proc).catch(procError);
    } else {
      personalService.save(this.state).then(proc).catch(procError);
    }
  }

  render() {
    return (
      <>
        <InfoModal
          show={this.state.showInfo}
          title="Procesado"
          message={this.state.infoMsg}
          closeCallback={() => this.setState({ showInfo: false })}
        ></InfoModal>
        <ProcesandoModal show={this.state.processing} />
        <ErrorModal
          show={this.state.showError}
          message={this.state.errorMsg}
          closeCallback={() => {
            this.setState({ showError: false });
          }}
        />
        <Form
          noValidate
          validated={this.state.formvalidated}
          onSubmit={this.handleSubmit}
        >
          <Card>
            <Card.Header className="card-header">Datos Personales</Card.Header>
            <Card.Body>
              <Form.Row>
                <Form.Group as={Col} controlId="name">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    value={this.state.name}
                    autoComplete="off"
                    onChange={(event) => {
                      this.setState({ name: event.target.value });
                    }}
                    required
                    type="text"
                    placeholder="Nombre"
                  />
                  <Form.Control.Feedback type="invalid">
                    El nombre del usuario es obligatorio.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="lastname">
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control
                    value={this.state.lastname}
                    autoComplete="off"
                    onChange={(event) => {
                      this.setState({ lastname: event.target.value });
                    }}
                    required
                    type="text"
                    placeholder="Apellido"
                  />
                  <Form.Control.Feedback type="invalid">
                    El apellido del usuario es obligatorio.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}  controlId="sede">
                  <Form.Label>Sede</Form.Label>
                  <Form.Control
                    isInvalid={this.state.sede === 0}
                    as="select"
                    value={this.state.sede}
                    required
                    onChange={(event) => {
                      this.setState({ sede: event.target.value });
                    }}
                  >
                    {this.state.sedelist.map((sedes, index) => (
                      <option key={index} value={sedes.id}>
                        {sedes.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}  controlId="empresa">
                  <Form.Label>Empresa</Form.Label>
                  <Form.Control
                    isInvalid={this.state.empresa === 0}
                    as="select"
                    value={this.state.empresa}
                    required
                    onChange={(event) => {
                      this.setState({ empresa: event.target.value });
                    }}
                  >
                    {this.state.empresalist.map((empresas, index) => (
                      <option key={index} value={empresas.id}>
                        {empresas.nombre}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs="2" sm="1" controlId="dnitype">
                  <Form.Label>Cédula</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.dnitype}
                    onChange={(event) => {
                      this.setState({ dnitype: `${event.target.value}` });
                    }}
                  >
                    <option>V</option>
                    <option>E</option>
                    <option>N/A</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs="1" sm="2" controlId="dni">
                  <Form.Label> &nbsp;</Form.Label>
                  <Form.Control
                    value={this.state.dni}
                    autoComplete="off"
                    onChange={(event) => {
                      this.setState({ dni: `${event.target.value}` });
                    }}
                    required
                    type="number"
                    placeholder="Cédula"
                  />
                  <Form.Control.Feedback type="invalid">
                    El número de cédula debe ser válido.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs="4" sm="3" controlId="phone">
                  <Form.Label>Télefono</Form.Label>
                  <Form.Control
                    value={this.state.phone}
                    autoComplete="off"
                    onChange={(event) => {
                      this.setState({ phone: `${event.target.value}` });
                    }}
                    type="text"
                    required
                    placeholder="Télefono"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row style={{ display: "none" }}>
                <Form.Group as={Col} xs="12" sm="6" controlId="job">
                  <Form.Label>Puesto</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.job}
                    onChange={(event) => {
                      this.setState({ job: event.target.value });
                    }}
                  >
                    <option key="-1" value="-1">
                      Puesto...
                    </option>
                    {this.state.jobs.map((option, index) => (
                      <option key={index} value={index}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="address">
                  <Form.Label>Dirección</Form.Label>
                  <Form.Control
                    value={this.state.address}
                    autoComplete="off"
                    onChange={(event) => {
                      this.setState({ address: `${event.target.value}` });
                    }}
                    required
                    as="textarea"
                    placeholder=""
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="observation">
                  <Form.Label>Observaciones</Form.Label>
                  <Form.Control
                    value={this.state.observation}
                    onChange={(event) => {
                      this.setState({
                        observation: `${event.target.value}`,
                      });
                    }}
                    autoComplete="off"
                    as="textarea"
                    placeholder=""
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  controlId="isActive"
                  style={{ display: "none" }}
                >
                  <Form.Check
                    checked={this.state.isActive}
                    onChange={(event) => {
                      this.setState({ isActive: event.target.checked });
                    }}
                    type="checkbox"
                    label="Activo"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="isSystemUser">
                  <Form.Check
                    checked={this.state.isSystemUser}
                    onChange={(event) => {
                      this.setState({ isSystemUser: event.target.checked });
                    }}
                    type="checkbox"
                    label="Es Usuario del Sistema"
                  />
                </Form.Group>
              </Form.Row>
            </Card.Body>
          </Card>
          {this.state.isSystemUser ? (
            <Accordion key="userdata" className="my-3">
              <Card className="ma-0">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="userdata"
                  className="card-header"
                >
                  Datos de Usuario
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="userdata">
                  <Card.Body className="pa-0">
                    <Form.Row>
                      <Form.Group as={Col} md="6" controlId="username">
                        <Form.Label>Nombre de Usuario</Form.Label>
                        <Form.Control
                          as="input"
                          value={this.state.username}
                          autoComplete="off"
                          onChange={(event) => {
                            this.setState({ username: event.target.value });
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} md="6" xs="12" controlId="role">
                        <Form.Label>Rol</Form.Label>
                        <Form.Control
                          isInvalid={this.state.role === 0}
                          as="select"
                          value={this.state.role}
                          onChange={(event) => {
                            this.setState({ role: event.target.value });
                          }}
                        >
                          {this.state.rolelist.map((rol, index) => (
                            <option key={index} value={rol.id}>
                              {rol.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} md="6" controlId="password">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                          as="input"
                          type="password"
                          value={this.state.password}
                          autoComplete="off"
                          disabled={this.state.isPasswordSet}
                          isInvalid={
                            !this.state.isPasswordSet &&
                            this.state.password !== this.state.password2
                          }
                          onChange={(event) => {
                            this.setState({ password: event.target.value });
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Las contraseñas deben coincidir.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="password2"
                        style={
                          this.state.isPasswordSet ? { display: "none" } : {}
                        }
                      >
                        <Form.Label>Confirme Contraseña</Form.Label>
                        <Form.Control
                          as="input"
                          type="password"
                          value={this.state.password2}
                          autoComplete="off"
                          onChange={(event) => {
                            this.setState({
                              password2: event.target.value,
                            });
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Form.Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ) : (
            <div />
          )}

          <Accordion
            key="bancokey"
            className="my-3"
            style={{ display: "block" }}
          >
            <Card className="ma-0">
              <Accordion.Toggle
                as={Card.Header}
                eventKey="bancokey"
                className="card-header"
              >
                Datos de Cuenta Bancaria
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="bancokey">
                <Card.Body className="pa-0">
                  <Form.Row>
                    <Form.Group as={Col} md="4" xs="12" controlId="bankid">
                      <Form.Label>Banco</Form.Label>
                      <Form.Control
                       isInvalid={this.state.bankid === 0}
                        as="select"
                        value={this.state.bankid}
                        onChange={(event) => {
                          this.setState({ bankid: event.target.value });
                        }}
                      >
                        {this.state.banks.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="4" xs="12" controlId="accounttype">
                      <Form.Label>Tipo de Cuenta</Form.Label>
                      <Form.Control
                        as="select"
                        value={this.state.accounttype}
                        onChange={(event) => {
                          this.setState({
                            accounttype: event.target.value,
                          });
                        }}
                      >
                        <option key="1" value="1">
                          Ahorro
                        </option>
                        <option key="2" value="2">
                          Corriente
                        </option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      xs="12"
                      controlId="isownaccount"
                      className="my-auto"
                    >
                      <Form.Label> &nbsp;</Form.Label>
                      <Form.Check
                        checked={this.state.isownaccount}
                        onChange={(event) => {
                          this.setState({
                            isownaccount: event.target.checked,
                          });
                        }}
                        type="checkbox"
                        label="Cuenta Propia"
                        className="mb-2"
                      />
                    </Form.Group>
                  </Form.Row>
                  {!this.state.isownaccount ? (
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        xs="12"
                        controlId="accountdni"
                      >
                        <Form.Label>Cédula del Titular</Form.Label>
                        <Form.Control
                          value={this.state.accountdni}
                          autoComplete="off"
                          onChange={(event) => {
                            this.setState({
                              accountdni: `${event.target.value}`,
                            });
                          }}
                          type="text"
                          placeholder="Nro Cédula"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        xs="12"
                        controlId="accountname"
                      >
                        <Form.Label>Nombre del Titular</Form.Label>
                        <Form.Control
                          value={this.state.accountname}
                          autoComplete="off"
                          onChange={(event) => {
                            this.setState({
                              accountname: `${event.target.value}`,
                            });
                          }}
                          type="text"
                          placeholder="Nombre"
                        />
                      </Form.Group>
                    </Form.Row>
                  ) : (
                    <div />
                  )}
                  <Form.Row>
                    <Form.Group as={Col} controlId="accountno">
                      <Form.Label>Nro de Cuenta</Form.Label>
                      <Form.Control
                        value={this.state.accountno}
                        autoComplete="off"
                        onChange={(event) => {
                          this.setState({
                            accountno: `${event.target.value}`,
                          });
                        }}
                        type="text"
                        placeholder="Nro de Cuenta"
                      />
                    </Form.Group>
                  </Form.Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Form.Row className="align-items-center my-2">
            <Col className="mx-8">
              <span></span>
            </Col>
            <Col className="mx-8">
              <Button type="submit" variant="primary" block
                disabled={this.state.activoAll  }
              >
                Guardar Cambios
              </Button>
            </Col>
            <Col className="mx-8">
              <span></span>
            </Col>
          </Form.Row>
        </Form>
      </>
    );
  }
}

export default withRouter(Personal);
