import React, { Component } from "react";
import AddSpinner from "componentes/comunes/AddSpinner";
import asistenciaService from 'servicios/asistenciaService';
import { Form, FormControl, InputGroup, Col, Row } from "react-bootstrap";
import ProcesandoModal from 'componentes/comunes/ProcesandoModal';
import { withRouter } from "react-router";

class AddAsistencias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asistencias: [],
      newAsistenciaData: {
        codigo: "",
        sedeco: "",
      },
      isShow: false,
      processing: false,
      message: "",
      activo: false,
      visible: "block",
    };
  }

  toggleShow = () => {
    this.setState((state) => ({
      isShow: !state.isShow,
      activo: !state.activo,
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.toggleShow();
    let proc = (r) => {
      this.toggleShow();
      if (r.status === 200 && r.data.status !== "failed") {
        this.setState({ newAsistenciaData: { codigo: "" } });
        setTimeout(() => {
          this.setState({
            processing: true,
            message: r.data.message,
          });
        }, 200);
        document.getElementById("codigo").focus();
        setTimeout(() => {
          this.setState({
            processing: false,
          });
        }, 3000);
      } else {
        var Error;
        if (r.data.errors.codigo) {
          Error = r.data.errors.codigo;
        }
        if (r.data.errors.sedeco) {
          Error = r.data.errors.sedeco;
        }
        setTimeout(() => {
          this.setState({
            processing: true,
            message: Error,
          });
        }, 200);
        setTimeout(() => {
          this.setState({
            processing: false,
          });
        }, 3000);
        this.setState({ newAsistenciaData: { codigo: "" } });
      }
    };

    let procError = (r) => {
      this.setState({
        processing: false,
      });
    };
    asistenciaService
      .create(this.state.newAsistenciaData)
      .then(proc)
      .catch(procError);
    return false;
  };

  onChangeAddAsistenciaHandler = (e) => {
    let { newAsistenciaData } = this.state;
    newAsistenciaData[e.target.name] = e.target.value;
    newAsistenciaData["sedeco"] = localStorage.getItem("sedeco");
    this.setState({ newAsistenciaData });
  };


  render() {
    return (
      <div>
        {this.state.processing && (
          <ProcesandoModal
            show={this.state.processing}
            ShowSpin={!this.state.isShow}
            message={this.state.message}
          />
        )}

        <Row className="mx-auto">
          <Col md="12">
            <Form>
              <Form.Row>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <svg
                        width="1em"
                        height="1.2em"
                        viewBox="0 0 16 16"
                        className="bi bi-bar-chart-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="1" height="14" x="1" y="1" rx="1" />
                        <rect width="1" height="12" x="3" y="1" rx="1" />
                        <rect width="1" height="12" x="6" y="1" rx="1" />
                        <rect width="1" height="12" x="9" y="1" rx="1" />
                        <rect width="1" height="12" x="12" y="1" rx="1" />
                        <rect width="1" height="14" x="15" y="1" rx="1" />
                      </svg>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    name="codigo"
                    value={this.state.newAsistenciaData.codigo}
                    onChange={this.onChangeAddAsistenciaHandler}
                    required
                    autoFocus={true}
                    disabled={this.state.activo}
                    type="password"
                    id="codigo"
                    placeholder="Ingrese su código"
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.handleSubmit(event);
                      }
                    }}
                  />
                  {this.state.isShow ? (
                    <AddSpinner animation="grow" variant="primary"></AddSpinner>
                  ) : null}
                </InputGroup>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(AddAsistencias)