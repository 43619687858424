import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  secondary: {
    backgroundColor: theme.palette.info.light,
    "& .MuiButton-label": {
      color: "#212121",
    },
  },
  primary: {
    backgroundColor: theme.palette.secondary.light,
    "& .MuiButton-label": {
      color: "#212121",
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    "& .MuiButton-label": {
      color: "#212121",
    },
  },
}));

function ActionButton(props) {
  const classes = useStyles();

  const { color, children, onClick } = props;

  return (
    <Button
      className={`${classes.root} ${classes[color]}`}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default ActionButton;
