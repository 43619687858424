import React, { Component } from 'react';
import { Form, Card, Col, Button, Table, Accordion,OverlayTrigger,Tooltip } from 'react-bootstrap';
import { withRouter } from "react-router";
import userService from "servicios/usuarioService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ProcesandoModal from 'componentes/comunes/ProcesandoModal';
import "style/formularios.css";
class UsuarioReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //user data
            id: 0,
            name: '',
            lastname: '',
            dni: '',
            email: '',
            isLock: false,
            isActive: true,
            dnitype: 'V',
            //view state
            processing: false,
            activeAcordeon: "params",
            reportdata: [],
        };
        this.handleSubmit.bind(this);
        this.saveProcess.bind(this);
        this.componentDidMount.bind(this);

    }
    componentDidMount() {
        if (this.props.match && this.props.match.params.id) {
            userService.findById(this.props.match.params.id).then(r => {
                this.setState(r.data);
            });
        }
    }


    handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ formvalidated: false });
        } else {
            this.setState({ formvalidated: true });
        }
        this.setState({ formvalidated: true });
        event.preventDefault();
        event.stopPropagation();
        this.saveProcess();
    };

    saveProcess() {
        this.setState({
            name: this.state.name.toUpperCase(),
            lastname: this.state.lastname.toUpperCase(),
            email: this.state.email.toLowerCase(),
            processing: true,
        });
        userService.find(this.state).then((r) => {
            let data = r.data;
            this.setState({ reportdata: data, processing: false, activeAcordeon: '' });
        });
    }

    render() {
        return (
            <>
                <ProcesandoModal show={this.state.processing} />
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row >
                        <Col>
                        <Accordion activeKey={this.state.activeAcordeon} 
                        className="my-3" 
                        onSelect={e => this.setState({ activeAcordeon: e })}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="params" >
                                    Parámetros
                            </Accordion.Toggle>
                                <Accordion.Collapse eventKey="params">
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId="name">
                                                <Form.Label>Nombre</Form.Label>
                                                <Form.Control
                                                    autoComplete="off"
                                                    value={this.state.name}
                                                    onChange={(event) => { this.setState({ name: event.target.value }); }}
                                                    type="text" placeholder="Nombre" />
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId="dni">
                                                <Form.Label>Cédula</Form.Label>
                                                <Form.Control
                                                    value={this.state.dni}
                                                    autoComplete="off"
                                                    onChange={(event) => { this.setState({ dni: `${event.target.value}` }); }}
                                                    type="number"
                                                    placeholder="Cédula" />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col} controlId="email">
                                                <Form.Label>Correo Electrónico</Form.Label>
                                                <Form.Control
                                                    value={this.state.email}
                                                    autoComplete="off"
                                                    onChange={(event) => { this.setState({ email: `${event.target.value}` }); }}

                                                    type="email"
                                                    placeholder="ejemplo@ejemplo.com" />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row className="align-items-center my-8">
                                            <Col className="mx-8">
                                                <span></span>
                                            </Col>
                                            <Col className="mx-8">
                                                <Button type="submit" block>Generar Reporte</Button>
                                            </Col>
                                            <Col className="mx-8">
                                                <span></span>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        </Col>
                    </Form.Row>
                    <Form.Row >
                        <Col>
                        <Table striped bordered hover size="sm"   >
                            <thead>
                                <tr>
                                    <th>Cédula</th>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody className="row-cursor-pointer">
                                {this.state.reportdata.map((w, index) =>
                                    <tr key={index}>
                                        <td>{w.dnitype}{w.dni}</td>
                                        <td>{`${w.name} ${w.lastname}`}</td>
                                        <td>{w.email}</td>
                                        <td className="column-centred">
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="button-tooltip-2">Editar Registro</Tooltip>}
                                            >
                                                 <FontAwesomeIcon 
                                                    icon={faEdit} 
                                                    onClick={()=>this.props.history.push(`/usuarios/${w.id}`)}/>
                                            </OverlayTrigger>
                                            </td>

                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        </Col>
                    </Form.Row>
                </Form>



            </>
        );
    }
}

export default withRouter(UsuarioReport);