import React, { useState, useEffect } from "react";

//formulario Material ui
import { Grid, Paper } from "@material-ui/core";
import CambiarPasswordModal from 'componentes/personal/CambiarPasswordModal';

import Popup from "componentes/controls/Popup";
import { getRol, getUserId } from "helpers/auth-helpers";

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { useForm, Form } from "componentes/useForm";

// carga servicios
import personalService from 'servicios/personalService';
import rolService from 'servicios/rolservice';

// carga modales
import ProcesandoModal from "componentes/comunes/ProcesandoModal";
import { errorValues } from "../../helpers/helpers";

// carga varios
import { useParams, useHistory } from "react-router-dom";

// carga sweetalert
import swal from "sweetalert";

import { esES } from "@material-ui/core/locale";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import Notification from "componentes/controls/Notifications";

//import Controls from "componentes/comunes/Controls";
import Controls from "componentes/controls/Controls";

//import { calculateBorderBoxPath } from "html2canvas/dist/types/render/bound-curves";






const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      "& .MuiInputBase-root": {
        padding: 0,
      },
      "& .MuiFormControl-root": {
        width: "100%",
        marging: theme.spacing(1),
        padding: theme.spacing(0),
        paddingBottom: theme.spacing(2),
      },
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    pageContent: { marging: theme.spacing(5), padding: theme.spacing(1) },
    media: {
      height: 200,
    },
    paper: {
      paddingVertical: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      paddingBottom: 6,
      paddingTop: 6,
      paddingLeft: 8,
      paddingRight: 8,
    },
    fontFecha: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(0),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      width: 200,
    },
  }),
  esES
);

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 18,
  },
});

const tipos = [
    {id: 'V', name: 'VENEZOLANO'},
    {id: 'E', name: 'EXTRANJERO'},
    {id: 'N/A', name: 'N/A'},
];




const initialPersonalState = {
    id: 0,
    name: '',
    lastname: '',
    dni: '',
    isActive: true,
    dnitype: 'V',
    observation: '',
    phone: '',
    address: '',
    email: '',


    job: -1,
    username: '',
    password: '',
    password2: '',
    role: 0,
    rolelist: [],
    //view state
    formvalidated: false,
    showSearch: true,
    processing: false,
    showError: false,
    showChangePassword: false,
    errorMsg: '',
    infoMsg: '',
    showInfo: '',
};




const MiUsuario4 = (props) => {

  let { id } = useParams();
  let history = useHistory();

  const [roles, setRoles] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });



  async function loadRole() {
    await rolService
      .find()
      .then((r) => setRoles(r.data))
      .catch((e) => console.log(e.response));
  }

  async function loadPersonal() {
    await personalService
//      .findById(localStorage.getItem("user_id"))
      .findById(getUserId())
      .then((response) => {
          console.log(response)
        setValues(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

     //validacion de formulario
    const validate = (fieldValues = values) => {
      if (typeof value !== undefined) {
        Object.entries(fieldValues).forEach(entry => {
        const [key, value] = entry;
        if (value !== null) {
          if (typeof value === 'boolean') {
            setValues({ ...values, [key]: value? 1:0 });
          } else if (key === 'fecha') {
            setValues({ ...values, [key]: values });
          } else if (typeof value === 'number') {
            setValues({ ...values, [key]: value });
          } else {
            setValues({ ...values, [key]: value.toUpperCase() });
          }
        }
      });
    }

    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "Campo obligatorio";

    if ("lastname" in fieldValues)
      temp.lastname = fieldValues.lastname ? "" : "Campo obligatorio";

    if ("dnitype" in fieldValues)
      temp.dnitype = fieldValues.dnitype ? "" : "Campo obligatorio";

    if ("dni" in fieldValues)
      temp.dni = fieldValues.dni ? "" : "Campo obligatorio";

    if ("phone" in fieldValues)
      temp.phone = fieldValues.phone ? "" : "Campo obligatorio";

    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "Campo obligatorio";



    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
  } = useForm(initialPersonalState, true, validate);

  const mensaje = () => {
    swal({ text: "Registro Exitoso", icon: "success" });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  }

    const pwdOrEdit = (item, resetForm) => {
        setNotify({
            isOpen: true,
            message: "El registro esta siendo procesado",
            type: "warning",
          });

        let proc = (r) => {
            if (r.data.id) {
                setNotify({
                isOpen: true,
                message: "Registro actualizado con éxito!!!",
                type: "success",
                autoHideDuration: 5000
                });
                mensaje();
            }
        };
        let procError = (e) => {
            setNotify({
            isOpen: true,
            message: e,
            type: "warning",
            autoHideDuration: 5000
            });
            swal({ text: e, icon: "warning" });
        };

        personalService.updatePassword(item).then(proc).catch(procError);
        setOpenPopup(false);

    }

  //manejo del envio del formulario add or edit
  const addOrEdit = (personal, resetForm) => {
      setNotify({
        isOpen: true,
        message: "El registro esta siendo procesado",
        type: "warning",
      });
    if (personal.id === 0) {
      try {
        let proc = (r) => {
          if (r.data.id) {
            setNotify({
              isOpen: true,
              message: "Registro ingresado con éxito!!!",
              type: "success",
            });
            mensaje();
          }
//          setProcessing(false);
        };
        let procError = (e) => {
            setNotify({
              isOpen: true,
              message: e,
              type: "warning",
            });
            swal({ text: e, icon: "warning" });
        };

        personalService.save(personal).then(proc).catch(procError);
        console.log("valores de la personal ingresados ", personal);
      } catch (e) {
        console.log("error =>", e);
      }
    } else {
      //llamar servicio para el update put /categorias/:id
      try {
        let proc = (r) => {
          if (r.data.id) {
            setNotify({
              isOpen: true,
              message: "Registro actualizado con éxito!!!",
              type: "success",
            });
            mensaje();
          }
        };
        let procError = (e) => {
            setNotify({
              isOpen: true,
              message: e,
              type: "warning",
            });
            swal({ text: e, icon: "warning" });
        };
        personalService.update(personal).then(proc).catch(procError);
      } catch (e) {
        console.log("error =>", e);
      }
    }
    resetForm();

    //limpiar formulario
    setValues(initialPersonalState);

  };

  useEffect(() => {
    loadRole();
    loadPersonal();
  }, []);

  const classes = useStyles();

  return (
    <>
      <Controls.PageHeader
        title="Datos de Usuario"
        icon={<DriveEtaIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent} >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"

        >
        <Form style= {{marginTop:'2px',padding:'12px 12px 10px 10px',border: 'solid 1px gray',width: '620px'}} onSubmit={onSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Controls.Input
                    name="name"
                    label="Nombre"
                    value={values.name}
                    onChange={handleInputChange}
                    error={errors.name}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Controls.Input
                    name="lastname"
                    label="Apellido"
                    value={values.lastname}
                    onChange={handleInputChange}
                    error={errors.lastname}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Controls.Select
                    name="dnitype"
                    label="Nacionalidad"
                    value={values.dnitype}
                    onChange={handleInputChange}
                    options={tipos}
                    campo={"name"}
                    error={errors.dnitype}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Controls.Input
                    name="dni"
                    label="Cédula"
                    value={values.dni}
                    onChange={handleInputChange}
                    error={errors.dni}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Controls.Input
                    name="phone"
                    label="Teléfono"
                    value={values.phone}
                    onChange={handleInputChange}
                    error={errors.phone}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controls.Input
                    name="address"
                    label="Dirección"
                    value={values.address}
                    onChange={handleInputChange}
                    error={errors.address}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controls.Input
                    name="observation"
                    label="Observaciones"
                    value={values.observation}
                    onChange={handleInputChange}
                    error={errors.observation}
                    />
                </Grid>


                <Grid item xs={6}>
                    <Controls.Input
                    name="username"
                    label="Nombre de Usuario"
                    value={values.username}
                    onChange={handleInputChange}
                    error={errors.username}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Controls.Select
                    name="role"
                    label="Rol"
                    disabled={getRol() === 1?false:true}
                    value={values.role}
                    onChange={handleInputChange}
                    options={roles}
                    campo={"name"}
                    error={errors.role}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Controls.Input
                    name="password"
                    label="Contraseña"
                    type="password"
                    disabled
                    value={values.password}
                    onChange={handleInputChange}
                    error={errors.password}
                    />
                </Grid>

                <Grid item xs={6}>
                    <a href="#"  variant="primary" block onClick={() => setOpenPopup(true) }>¿Cambiar Contraseña?</a>
                </Grid>

                <Grid item xs={12}>
                    <div>
                        <Controls.Button type="submit" text="Registrar" />
                        <Controls.Button
                        text="Limpiar"
                        color="default"
                        onClick={resetForm}
                        />
                    </div>
                </Grid>
            </Grid>

          </Form>
        </Grid>

        <Popup
            title="Cambiar contraseña"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
        >
            <CambiarPasswordModal  pwdOrEdit={pwdOrEdit} />
        </Popup>
        <Notification notify={notify} setNotify={setNotify} />

      </Paper>
  </>
  );
};

export default MiUsuario4;
