import axios from 'axios';

function AxiosConf() {
   axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    axios.defaults.headers['Content-Type'] = 'application/json';

    axios.interceptors.request.use(config => {
        // Edit request config
        config.headers.get['Content-Type'] = 'application/json';
        if (localStorage.getItem('user_token')) {//add token
            const token = localStorage.getItem('user_token');
            config.headers.Authorization = token ? `Bearer ${token}` : '';
        } else if ("/auth/login" === config.url) {
            config.headers.Authorization = 'Bearer ';
        } else {
            window.location = "/";
        }
        return config;
    }, error => {
        console.log(error);
        return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
        // Edit response config
        return response;
    }, error => {
        console.log(error.response.config.url !== "/auth/login");
        if (error.response.status === 401 && error.response.config.url !== "/auth/login") {
            window.location = "/";
        } else {
            return Promise.reject(error);
        }
        return Promise.reject(error);

    });

}
export default AxiosConf;