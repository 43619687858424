import {
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import React from "react";

export default function Switche(props) {
  const { name, label, value, onChange, checked } = props;
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          value={value}
          name={name}
          color="primary"
        />
      }
      label={label}
    />
  );
}

