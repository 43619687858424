import React, { useState, useEffect } from "react";
import { Grid, makeStyles, createMuiTheme } from "@material-ui/core";
import { esES } from "@material-ui/core/locale";

//componentes
import { useForm, Form } from "componentes/useForm";
import Controls from "componentes/controls/Controls";

//servicios
import marcaService from "servicios/marcaService";
import vehiculoService from "servicios/vehiculoService";
import empresaService from "servicios/empresaService";
import combustibleService from "servicios/combustibleService";
import tipovehiculoService from "servicios/tipovehiculoService";
import sedeservice from "servicios/sedeService";
import { get } from "react-hook-form";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      "& .MuiInputBase-root": {
        padding: theme.spacing(0.5),
      },
      "& .MuiOutlinedInput-inputMultiline": {
        padding: theme.spacing(2),
      },
      "& .MuiFormControl-root": {
        width: "100%",
        margin: theme.spacing(0.5),
        padding: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
      "& > * + *": {
        marginTop: theme.spacing(0.5),
      },
    },
    pageContent: { margin: theme.spacing(1), padding: theme.spacing(1) },
    media: {
      height: 200,
    },
    paper: {
      paddingVertical: theme.spacing(1),
      textAlign: "left",
      color: theme.palette.text.secondary,
      paddingBottom: 6,
      paddingTop: 2,
      paddingLeft: 6,
      paddingRight: 6,
    },
    fontFecha: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      width: 200,
    },
    textarea: {
      width: "90%",
    },
    normalLong: {
      width: "25%",
    },
  }),
  esES
);

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 12,
  },
});

const initialFValues = {
  id: 0,
  rif: "",
  nombre: "",
  telefono: "",
};

function EmpresasForm(props) {

  const classes = useStyles();
  const { addOrEdit, recordForEdit } = props;

  //validacion de formulario
  const validate = (fieldValues = values) => {
    Object.entries(fieldValues).forEach(entry => {
      const [key, value] = entry;
      if (typeof value !== 'number') {
        setValues({ ...values, [key]: value.toUpperCase() });
      }
    });
    let temp = { ...errors };
    if ("rif" in fieldValues)
      temp.rif = fieldValues.rif ? "" : "Campo obligatorio";

    if ("nombre" in fieldValues)
      temp.nombre =
        fieldValues.nombre.length !== 0 ? "" : "Campo obligatorio";

    if ("telefono" in fieldValues)
      temp.telefono = fieldValues.telefono ? "" : "Campo obligatorio";


    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit !== null) {
      setValues({ ...recordForEdit });
      console.log("valores edit=>", recordForEdit);
    }
  }, [recordForEdit, setValues]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={4}>
            <Controls.Input
              name="rif"
              label="RIF"
              value={values.rif}
              onChange={handleInputChange}
              error={errors.rif}
            />
          </Grid>
          <Grid item xs={4}>
            <Controls.Input
              name="nombre"
              label="Nombre"
              value={values.nombre}
              onChange={handleInputChange}
              error={errors.nombre}
            />
          </Grid>
          <Grid item xs={4}>
            <Controls.Input
              name="telefono"
              label="Teléfono"
              value={values.telefono}
              onChange={handleInputChange}
              error={errors.telefono}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <Controls.Button type="submit" text="Enviar" />
              <Controls.Button
                text="Limpiar"
                color="default"
                onClick={resetForm}
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

export default EmpresasForm;
