import React from "react";
import { Line } from "@ant-design/charts";

function LineChart(props) {
/*
  const dataLine = [
    {
      dia: "Lunes",
      total: 120,
    },
    {
      dia: "Martes",
      total: 100,
    },
    {
      dia: "Miercoles",
      total: 180,
    },
    {
      dia: "Jueves",
      total: 80,
    },
    {
      dia: "Viernes",
      total: 160,
    },
    {
      dia: "Sabado",
      total: 70,
    },
    {
      dia: "Domingo",
      total: 50,
    },
  ];
*/
  const configLine = {
    data: props.dataLine,
    height: 360,
    xField: "dia",
    yField: "total",
    color: "#01b075",
    point: {
      size: 5,
      shape: "diamond",
      color: "#01b075",
    },
    label: {
      style: {
        fill: "#aaa",
        fontSize: 16,
        stroke: "#464033",
        fontWeight: 300,
        textAlign: "center",
        textBaseline: "middle",
        shadowColor: "#464033",
        shadowBlur: 10,
      },
    },
  };

  return <Line {...configLine} style={{ backgroundColor: "#f5f5f5" }} />;
}

export default LineChart;
