/**
 * Login services
 */
import Axios from "axios";

export default {
    async login (username, password) {
        return  await Axios.post(
            "/auth/login",
            { email: `${username}`, password: `${password}` }
            );
    },

    async logout() {
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_sede');
        localStorage.removeItem('user_rol');
        return  await Axios.post(
            "/auth/logout",
            { }
            );
    }
}
