import React, { useState, useEffect } from "react";
import { Grid, makeStyles, createMuiTheme } from "@material-ui/core";
import { esES } from "@material-ui/core/locale";

//componentes
import { useForm, Form } from "componentes/useForm";
import Controls from "componentes/controls/Controls";
import { getSede, getRol } from "helpers/auth-helpers";
//SERVICIOS
import recaudacionService from 'servicios/recaudacionService';
// carga sweetalert
import swal from "sweetalert";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      "& .MuiInputBase-root": {
        padding: theme.spacing(0.5),
      },
      "& .MuiOutlinedInput-inputMultiline": {
        padding: theme.spacing(2),
      },
      "& .MuiFormControl-root": {
        width: "100%",
        margin: theme.spacing(0.5),
        padding: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
      "& > * + *": {
        marginTop: theme.spacing(0.5),
      },
    },
    pageContent: { margin: theme.spacing(1), padding: theme.spacing(1) },
    media: {
      height: 200,
    },
    paper: {
      paddingVertical: theme.spacing(1),
      textAlign: "left",
      color: theme.palette.text.secondary,
      paddingBottom: 6,
      paddingTop: 2,
      paddingLeft: 6,
      paddingRight: 6,
    },
    fontFecha: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      width: 200,
    },
    textarea: {
      width: "90%",
    },
    normalLong: {
      width: "25%",
    },
  }),
  esES
);

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 12,
  },
});



function RecaudacionForm(props) {
  const classes = useStyles();
  const { addOrEdit, recordForEdit, divisas, sedes } = props;

  const convertToDefEventPara = (value) => {
    let nuevafecha = value;
//    console.log(nuevafecha);
    setValues({ ...values, fecha: nuevafecha });
    checkEquals();
  }

//TODO: en observacion
  const initialFValues = {
    id: null,
    sede: props.sede,
    canal: "",
    recaudador: "",
    divisa: 1,
    denominacion: null,
    serial: "",
    retirado_por: "",
    fecha: new Date(),
    turno: "",
  };
  //validacion de formulario
  const validate = (fieldValues = values) => {
    Object.entries(fieldValues).forEach((entry) => {
      const [key, value] = entry;
      if ( key === "sede") {
        setValues({ ...values, [key]: value });
      }
      if ( key === "canal") {
        setValues({ ...values, [key]: value });
      }
      if ( key === "recaudador") {
        setValues({ ...values, [key]: value.toUpperCase() });
      }
      if ( key === "divisa") {
        setValues({ ...values, [key]: value });
      }
      if ( key === "denominacion") {
        setValues({ ...values, [key]: value });
      }
      if ( key === "serial") {
        setValues({ ...values, [key]: value.toUpperCase() });
      }
      if ( key === "retirado_por") {
        setValues({ ...values, [key]: value.toUpperCase() });
      }
      if ( key === "turno") {
        setValues({ ...values, [key]: value.toUpperCase() });
      }
    });
    let temp = { ...errors };

    if ("sede" in fieldValues)
      temp.sede = fieldValues.sede.length !== 0 ? "" : "Campo obligatorio";

    if ("canal" in fieldValues)
      temp.canal = fieldValues.canal.length !== 0 ? "" : "Campo obligatorio";

    if ("recaudador" in fieldValues)
      temp.recaudador = fieldValues.recaudador.length !== 0 ? "" : "Campo obligatorio";

    if ("divisa" in fieldValues)
      temp.divisa = fieldValues.divisa.length !== 0 ? "" : "Campo obligatorio";

    if ("denominacion" in fieldValues)
      temp.denominacion = fieldValues.denominacion.length !== 0 ? "" : "Campo obligatorio";

    if ("serial" in fieldValues)
      temp.serial = fieldValues.serial.length !== 0 ? "" : "Campo obligatorio";

    if ("retirado_por" in fieldValues)
      temp.retirado_por = fieldValues.retirado_por.length !== 0 ? "" : "Campo obligatorio";

    if ("fecha" in fieldValues)
      temp.fecha = fieldValues.fecha.length !== 0 ? "" : "Campo obligatorio";

    if ("turno" in fieldValues)
      temp.turno = fieldValues.turno.length !== 0 ? "" : "Campo obligatorio";


    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
//      console.log(values)
      addOrEdit(values, resetForm);
    }
  };

  const checkEquals = () => {
//    console.log(values.serial,' => ', values.fecha)
    if (values.serial.length>0) {
      recaudacionService.compare(values)
      .then((r)=>{
        if (r.data.msg) {
          swal({ text: r.data.msg, icon: r.data.err });
        }
      })
      .catch((e)=>console.log(e.mesagge))
    }
  }

  useEffect(() => {
    if (recordForEdit !== null) {
      setValues({ ...recordForEdit });
//      console.log("valores edit=>", recordForEdit);
    }
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={4}>
            <Controls.Select
              name="sede"
              label="Sede"
              value={values.sede}
              disabled={getRol()>1?true:false}
              onChange={handleInputChange}
              options={sedes}
              campo={"nombre"}
              error={errors.sede}
            />
          </Grid>

          <Grid item xs={4}>
            <Controls.Pickers
              name="fecha"
              label="Fecha del turno"
              value={values.fecha}
              onChange={convertToDefEventPara}
            />
          </Grid>

          <Grid item xs={4}>
            <Controls.Input
              name="turno"
              label="Turno"
              value={values.turno}
              onChange={handleInputChange}
              error={errors.turno}
            />
          </Grid>

          <Grid item xs={4}>
            <Controls.Select
              name="divisa"
              label="Divisa"
              value={values.divisa}
              onChange={handleInputChange}
              options={divisas}
              campo={"nombre"}
              error={errors.divisa}
            />
          </Grid>

          <Grid item xs={4}>
            <Controls.Input
              name="denominacion"
              label="Denominación"
              value={values.denominacion}
              onChange={handleInputChange}
              error={errors.denominacion}
            />
          </Grid>

          <Grid item xs={4}>
            <Controls.Input
              name="serial"
              label="Serial"
              value={values.serial}
              onChange={handleInputChange}
              onBlur={checkEquals}
              error={errors.serial}
            />
          </Grid>

          <Grid item xs={4}>
            <Controls.Input
              name="canal"
              label="Canal"
              value={values.canal}
              onChange={handleInputChange}
              error={errors.canal}
            />
          </Grid>

          <Grid item xs={4}>
            <Controls.Input
              name="recaudador"
              label="Recaudador"
              value={values.recaudador}
              onChange={handleInputChange}
              error={errors.recaudador}
            />
          </Grid>

          <Grid item xs={4}>
            <Controls.Input
              name="retirado_por"
              label="Retirado por"
              value={values.retirado_por}
              onChange={handleInputChange}
              error={errors.retirado_por}
            />
          </Grid>

          <Grid item xs={12}>
            <div>
              <Controls.Button type="submit" text="Enviar" />
              <Controls.Button
                text="Limpiar"
                color="default"
                onClick={resetForm}
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

export default RecaudacionForm;
