/**
 * Asistencia service
 */

import Axios from "axios";
import {getDates} from 'utils/dateutils';
const BASE_URL = '/asistencia';

export default {
    save(worker){        
        return new Promise(function(resolve, reject) {
            Axios.post(`${BASE_URL}`,format(worker)).then((r)=> {
                let res = r;
                res.data =  parse(r.data);
                resolve(res);
            }).catch(e=>reject(e));
        });
    },
    update(worker){
        return new Promise(function(resolve, reject) {
            Axios.put(`${BASE_URL}/${worker.id}`,format(worker)).then((r)=> {                
                let res = r;
                res.data = parse(r.data);             
                resolve(res);
            }).catch(e=>{
                reject(e)});
        });

    },
    
    find(data){
        return new Promise(function(resolve, reject) {
            Axios.get(BASE_URL).then((r)=> {
                let res = r;
                res.data = r.data.map(e=>parse(e)); 
                resolve(res);
            }).catch(e=>reject(e));
        });
    },

    report(data){
        return new Promise(function(resolve, reject) {
            console.info(data)
            let query = {desde: data.from, hasta: data.to};
            if (data.personalid && data.personalid>0){
                query.personal = data.personalid;
            }
            if (data.dni){
                query.cedula = data.dni;
            }
            if (data.sede>0) {
                query.sede = data.sede;
            }
            Axios.post(`${BASE_URL}/report`,query).then((r)=> {
                let res = r;
                //res.data = r.data.map(e=>parse(e)); 
                let reportdata = r.data.map(e=>parse(e));

                let sedeHeader = {};
                let daysheaders = [];
                let daysofweekheader = [];
                let monthHeader = {};
                let days = ['D','L','M','M','J','V','S']
                let monthnames = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
                for (let d of getDates(Date.parse( data.from ),Date.parse( data.to ))){
                    daysheaders.push({colspan: 1, name: d.getUTCDate(), dateformated: `${d.getUTCDate()}-${d.getUTCMonth()}-${d.getUTCFullYear()}`});
                    daysofweekheader.push({colspan: 1, name: days[d.getDay()]});
                    if (!monthHeader[d.getUTCMonth()]){
                        monthHeader[d.getUTCMonth()] = {colspan: 1, name: `${monthnames[d.getUTCMonth()]}-${d.getUTCFullYear()}`}
                    }else{
                        monthHeader[d.getUTCMonth()].colspan = monthHeader[d.getUTCMonth()].colspan + 1;
                    }
                    if (!sedeHeader[d.getUTCMonth()]){
                        if (typeof(reportdata[0]) !== 'undefined') {
                            sedeHeader[d.getUTCMonth()] = {colspan: 1, name: `${reportdata[0].sede}`}
                        }
                    }else{
                        sedeHeader[d.getUTCMonth()].colspan = sedeHeader[d.getUTCMonth()].colspan + 1;
                    }
                }


                let computeddata = {};
                for (let d of reportdata.sort((a,b)=>a.personalid-b.personalid)){
                    
                    if (!computeddata[d.personalid]){
                        computeddata[d.personalid] = {
                            personalname: d.personalname,
                            personalid: d.personalid,
                            dni: d.dni,      
                            values: Array.apply(null, Array(daysheaders.length)),
                        }
                    } 
                    if (!d.id){//sin datos de asistencia
                        continue;
                    } 
                    let index = daysheaders.findIndex(v=>v.dateformated===`${d.date.getUTCDate()}-${d.date.getUTCMonth()}-${d.date.getUTCFullYear()}`)
                    
                    computeddata[d.personalid].values[index] = d;
                }
                //add totals
                for (const property in computeddata){
                    //permit count
                    let d = computeddata[property];
                    d.paidpermitcount = d.values.filter(v=>v && v.ispaidworkpermit).length;
                    d.permitcount = d.values.filter(v=>v && v.isworkpermit).length;
                    d.noassistenacecount = d.values.filter(v=>!v || !v.id).length;
                    d.assistenacecount = d.values.filter(v=>v && v.id).length;
                }


                res.data = {headers: [Object.values(sedeHeader),Object.values(monthHeader),daysofweekheader,daysheaders], data: Object.values(computeddata)};
                resolve(res);
            }).catch(e=>reject(e));
        });
    },
    findById(id){
        return new Promise(function(resolve, reject) {
            Axios.get(`${BASE_URL}/${id}`).then((r)=> {
                let res = r;
                if (r.data.length>0){ 
                    res.data = parse(r.data[0]);                
                }else{
                    res.data = {};
                }
                resolve(res);
            }).catch(e=>reject(e));
        });
    },
    create(worker){
        return new Promise(function(resolve, reject) {
            Axios.post(`${BASE_URL}/entrada`, worker).then((r)=> {
                let res = r;
                res.data =  r.data;
                resolve(res);
            }).catch(e=>reject(e));
        });
    },

};

function format(data){

}


/** Traduce la data desde backend al formato del front */
function parse (data)  {
    return {
        id: data.id?data.id:0,
        personalname: data.personal_nombre,
        personalid: data.personal_id,
        dni: data.personal_cedula,
        date: new Date(data.fecha),
        indate: data.fecha_entrada?new Date(data.fecha_entrada):null,
        outdate: data.fecha_salida?new Date(data.fecha_salida):null,
        isworkpermit: data.es_permiso===1,
        ispaidworkpermit: data.es_permiso_pagado===1,
        sede: data.personal_sede,
    }
}