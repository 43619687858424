import React from "react";
import PageHeader from "componentes/PageHeader";
const OWNER = "Gruposulca";

function Headers(props) {
	const {title, subtitle, icon} = props;
	return (
		<>
			<PageHeader
				title={title}
				subtitle={OWNER}
				icon={icon}
			/>
		</>
	);
}
export default Headers;
