import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  makeStyles,
  useTheme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import login from "servicios/loginService";

import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

//user
import { getName, getRol, getUserId } from "helpers/auth-helpers";

//Material-ui Icons
import PrintIcon from "@material-ui/icons/Print";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import DomainIcon from "@material-ui/icons/Domain";
import SettingsIcon from "@material-ui/icons/Settings";
import FlagIcon from "@material-ui/icons/Flag";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

//temas

import { Link } from "react-router-dom";

const drawerWidth = 220;

const themes = createMuiTheme({
  typography: {
    subtitle1: {
      fontSize: 14,
      fontWeight: 600,
    },
    body2: {
      fontSize: 12,
      fontWeight: 500,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "fix",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor: "#10699B",
    paddingRight: theme.spacing(0.5),
  },
  listItem: {
    "& .MuiListItem-root": {
      marginRight: theme.spacing(0),
      minWidth: "18px",
    },
    alignContent: "center",
    minWidth: "18px",
    alignItems: "left",
    paddingRight: theme.spacing(0.5),
  },
  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#10699B",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }), //colo encabezado de la tabla
  },

  appBarShift: {
    flexGrow: 1,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 22,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    flexGrow: 1,
    width: drawerWidth,
    backgroundColor: "#e7e1e1", //fondo debajo del menu
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    flexGrow: 1,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(3) + 1,
    [theme.breakpoints.up("sm")]: {
      minWidth: theme.spacing(14),
    },
    minWidth: theme.spacing(10),
    backgroundColor: "#e7e1e1", //fondo debajo del menu
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // padding: theme.spacing(1, 1),
    marginBottom: theme.spacing(0),
    backgroundColor: "#10699B",
    minHeight: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#989393", //fondo debajo del menu
  },
  drawerContainer: {
    //sideBar menu
    overflow: "auto",
    backgroundColor: "#e7e1e1",
    padding: theme.spacing(0),
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(0.5),
    position: "fix",
  },
  icon: {
    color: "#231c1c",
    fontSize: 25,
    margin: theme.spacing(0.5),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
      color: "#eae9e9",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 27,
      padding: theme.spacing(0.5),
    },
  },
  iconSub: {
    color: "#757575",
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      padding: theme.spacing(0.5),
    },
  },
  btn: {
    color: "#fff",
    opacity: "0.6",
    padding: `0px ${theme.spacing(1)}px`,
    fontSize: "2.3rem",
    "&:hover": {
      color: "#fff",
    },
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
      color: "#eae9e9",
    },
  },
  titulo: {
    color: "#231c1c",
  },
  subtitulo: {
    color: "#231c1c",
  },
  small: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
  GridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: theme.spacing(0.5),
  },
  GridItemRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingRight: theme.spacing(0),
    textAlign: "left",
  },
}));

const NavBar2 = (props) => {
  const classes = useStyles();
  const [username, setUserName] = useState("");
  const [menu, setMenu] = useState({});
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [rol, setRol] = useState([]);
  // const rolUsuarios = [1, 2, 3, 4, 5];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickP = (item) => {
    let newData = { ...menu, [item]: !menu[item] };
    setMenu(newData);
  };

  const logoutThis = () => {
    login.logout();
    setUserName({ username: "" });
    props.history.push("/");
  };
  useEffect(() => {
    console.log(getName())
    setUserName(getName());
    setRol(getRol().toString());
  }, []);

  return (
    <div className={classes.root}>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              {"SIGES"}
            </Typography>
            <Typography
              variant="body2"
              noWrap
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              {"ver 0.1"}
            </Typography>

            <Nav className="ml-auto">
              <Nav.Item>
                <Nav.Link eventKey="disabled" style={{ color: "#ffffff" }}>
                  {username}
                </Nav.Link>
              </Nav.Item>
              <Nav.Link
                eventKey="link-2"
                onSelect={() => logoutThis()}
                style={{ color: "#ffffff" }}
              >
                <FontAwesomeIcon icon={faSignInAlt} color="#FFFFFF" />
                &nbsp;Salir
              </Nav.Link>
            </Nav>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <Typography
              variant="h6"
              noWrap
              style={{ color: "#fff", paddingRight: 12 }}
            >
              {"SIGES"}
            </Typography>
            <Typography
              variant="body2"
              noWrap
              style={{ color: "#fff", paddingRight: 12 }}
            >
              &nbsp;{"ver 0.1"}
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon style={{ color: "#fff" }} />
              ) : (
                <ChevronLeftIcon style={{ color: "#fff" }} />
              )}
            </IconButton>
          </div>
          <Divider />

          <div className={classes.drawerContainer}>
            <List component="nav" aria-label="main mailbox folders">
              {rol.includes("1") || rol.includes("2") ? (
                <Link to={`/home`}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <DashboardIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          primary="Escritorio"
                          className={classes.titulo}
                          variant="subtitle1"
                        >
                          {open ? "Escritorio" : ""}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Link>
              ) : (
                ""
              )}

              <Divider />

              {rol.includes("1") ||
              rol.includes("2") ||
              rol.includes("3") ||
              rol.includes("4") ||
              rol.includes("5") ? (
                <Link to={`/miusuario`}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <AccountBoxIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          primary="Perfil"
                          className={classes.titulo}
                          variant="subtitle1"
                        >
                          {open ? "Perfil" : ""}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Link>
              ) : (
                ""
              )}
              <Divider />
              {rol.includes("1") || rol.includes("2") || rol.includes("3") ? (
                <Link to={`/personal`}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <PeopleAltIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          primary="Personal"
                          className={classes.titulo}
                          variant="subtitle1"
                        >
                          {open ? "Personal" : ""}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Link>
              ) : (
                ""
              )}
              <Divider />
              {rol.includes("1") || rol.includes("2") || rol.includes("3") ? (
                <Link to={`/empresa`}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <HomeWorkIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          primary="Empresa"
                          className={classes.titulo}
                          variant="subtitle1"
                        >
                          {open ? "Empresa" : ""}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Link>
              ) : (
                ""
              )}
              <Divider />
              {rol.includes("1") || rol.includes("4") ? (
                <List component="div" disablePadding>
                  <Link to={`/recaudacion`}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <AccountBalanceIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            primary="Recaudación"
                            className={classes.titulo}
                            variant="subtitle1"
                          >
                            {open ? "Recaudación" : ""}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Link>
                </List>
              ) : (
                ""
              )}
              <Divider />
              {rol.includes("1") || rol.includes("2") ? (
                <List component="div" disablePadding>
                  <Link to={`/reportes`}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <PrintIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            primary="Recaudación"
                            className={classes.titulo}
                            variant="subtitle1"
                          >
                            {open ? "Reportes" : ""}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Link>
                </List>
              ) : (
                ""
              )}
              <Divider />
              {rol.includes("1") || rol.includes("2") ? (
                <ListItem
                  name="ajustes"
                  button
                  onClick={() => handleClickP("ajustes")}
                >
                  <ListItemIcon>
                    <SettingsIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        primary="Ajustes"
                        className={classes.titulo}
                        variant="subtitle1"
                      >
                        {open ? "Ajustes" : ""}
                      </Typography>
                    }
                  />
                  {menu["ajustes"] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              ) : (
                ""
              )}
              <Collapse
                in={menu["ajustes"] ? true : false}
                timeout="auto"
                unmountOnExit
              >
                {rol.includes("1") || rol.includes("2") ? (
                  <List component="div" disablePadding>
                    <Link to={`/estado`}>
                      {" "}
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <FlagIcon className={classes.iconSub} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              className={classes.titulo}
                            >
                              Estados
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Link>
                  </List>
                ) : (
                  ""
                )}
                {rol.includes("1") || rol.includes("2") ? (
                  <List component="div" disablePadding>
                    <Link to={`/sede`}>
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <DomainIcon className={classes.iconSub} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              className={classes.titulo}
                            >
                              Sedes
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Link>
                  </List>
                ) : (
                  ""
                )}
                {rol.includes("1") || rol.includes("2") ? (
                  <List component="div" disablePadding>
                    <Link to={`/divisa`}>
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <MonetizationOnIcon className={classes.iconSub} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              className={classes.titulo}
                            >
                              Divisas
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Link>
                  </List>
                ) : (
                  ""
                )}
              </Collapse>
            </List>
          </div>
        </Drawer>
        <main className={classes.content}>
          <Toolbar />
        </main>
      </ThemeProvider>
    </div>
  );
};

export default NavBar2;
