import Axios from "axios";
const BASE_URL = "/estado";

//Obtener todos los registros
const getAll = () => {
  return new Promise(function (resolve, reject) {
    Axios.get(BASE_URL)
      .then((r) => {
        let res = r;
        res.data = r.data.map((e) => parse(e));
        resolve(res);
      })
      .catch((e) => reject(e));
  });
};

const getEreport = () => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${BASE_URL}/reporte`)
      .then((r) => {
        let res = r;
        res.data = r.data.map((e) => parse(e));
        resolve(res);
      })
      .catch((e) => reject(e));
  });
};

const create = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.post(BASE_URL, format(data))
      .then((r) => {
        let res = r;
        console.log(r.data);
        res.data = parse(r.data);
        resolve(res);
      })
      .catch((e) => {
        let res = "";
        res = regError(e.response.data.errors);
        console.log(res);
        reject(res);
      });
  });
};

const update = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.put(`${BASE_URL}/${data.id}`, format(data))
      .then((r) => {
        let res = r;
        res.data = parse(r.data);
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const updateEditable = (id,data) => {
  console.log(data)
    return new Promise(function(resolve, reject) {
        Axios.put(`${BASE_URL}/editar/${id}`,data)
        .then((r)=> {
            let res = r;
            console.log(r)
            res.data = r.data;
            resolve(res);
        }).catch(e=>{console.log(e.datos);reject(e.message)});
    });
};

const remove = (id) => {
  return new Promise(function(resolve, reject) {
      Axios.delete(`${BASE_URL}/${id}`).then((r)=> {
          let res = r;
          console.log(r.data)
//                res.data = parse(r.data);
//            resolve(res);
      }).catch(e=>reject(e));
  });
};

function format(data) {
  return {
    id: data.id,
    nombre: data.nombre,
    activo: data.activo
  };
}

/** Traduce la data desde backend al formato del front */
function parse(data) {
  return {
    id: data.id,
    nombre: data.nombre,
    activo: data.activo
  };
}

const regError = (data) => {
  if (data !== null && data !== undefined) {
    var value = Object.values(data);
    var result = value.join("\n ");
    return result; // John, then 30
  }
};

export default {
  getAll,
  getEreport,
  create,
  update,
  remove,
};
