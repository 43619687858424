import Input from "./Input";
import RadioGroup from "./RadioGroup";
import CheckboxLabels from "./CheckboxLabels";
import Select from "./Select";
import Button from "./Button";
import ActionButton from './ActionButton';
import Pickers from './Pickers';
import PageHeader from './Headers';
import Switch from './Switche';

const Controls = {
    Input,
    RadioGroup,
    CheckboxLabels,
    Select,
    Button,
    ActionButton,
    Pickers,
    PageHeader,
    Switch
}

export default Controls;