import React,{ Component } from 'react';
import { Button,Col,Row,Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faInfo } from "@fortawesome/free-solid-svg-icons";
/**
 * Modal para mantener la ventana bloqueada mientras se termina una tarea
 */
class InfoModal extends Component{
    render(){
        return (
        <Modal show={this.props.show} centered size="sm" onHide={()=>this.props.closeCallback()}>
            <Modal.Header className="py-0">
                <Modal.Title>{this.props.title? this.props.title: 'Información'} </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{display: 'table'}}>
                <Row >
                    <Col xs="2"  className="align-middle">
                        <FontAwesomeIcon icon={faInfo} color="primary" size="2x" style={{color: "var(--primary)"}}/>
                    </Col>
                    <Col xs="10" className="text-center align-middle">
                        <span className="text-center align-middle" >{this.props.message}</span>
                    </Col>
                </Row>
                
            </Modal.Body>

            <Modal.Footer className="py-0">
                <Button variant="primary" onClick={()=>this.props.closeCallback()}>Aceptar</Button>
            </Modal.Footer>
        </Modal>

        
        );
    }
}
export default InfoModal;