import React, { useState, Component } from 'react';
import { useForm, Form } from "componentes/useForm";

import swal from "sweetalert";

import Controls from "componentes/controls/Controls";
//------------------------------------Material UI--------------------------------------------------------------------------------------------------//
import { Grid, makeStyles} from "@material-ui/core";
//-------------------------------------------------------------------------------------------------------------------------------------------------//

const useStyles = makeStyles(
    (theme) => ({
      root: {
        flexGrow: 1,
        "& .MuiInputBase-root": {
          padding: 0,
        },
        "& .MuiFormControl-root": {
          width: "100%",
          marging: theme.spacing(1),
          padding: theme.spacing(0),
          paddingBottom: theme.spacing(2),
        },
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
      pageContent: { marging: theme.spacing(5), padding: theme.spacing(1) },
      media: {
        height: 200,
      },
      paper: {
        paddingVertical: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
        paddingBottom: 6,
        paddingTop: 6,
        paddingLeft: 8,
        paddingRight: 8,
      },
      fontFecha: {
        marginTop: theme.spacing(0),
        padding: theme.spacing(0),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        width: 200,
      },
    }),

  );
  
/**
 */
 const initialPassword = {
    oldpassword: '',
    password: '',
    password2: '',
 }

const CambiarPasswordModal = (props) => {
    const classes = useStyles();
    const { pwdOrEdit } = props;
    const [open, setOpen] = useState(false);

    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
      });
    
      const mensaje = () => {
        swal({ text: "Cambio de contraseña Exitoso", icon: "success" });
      };
    
    //validacion de formulario
    const validate = (fieldValues = values) => {
        if (typeof value !== undefined) {
            Object.entries(fieldValues).forEach(entry => {
                const [key, value] = entry;
                setValues({ ...values, [key]: value });        
            });
        }
    
        let temp = { ...errors };
    
        if ("password" in fieldValues)
        temp.password = fieldValues.password ? "" : "Campo obligatorio";
    
        if ("password2" in fieldValues)
        temp.password2 = fieldValues.password2 ? "" : "Campo obligatorio";
    
        if ("oldpassword" in fieldValues)
        temp.oldpassword = fieldValues.oldpassword ? "" : "Campo obligatorio";
    
        setErrors({
        ...temp,
        });
    
        if (fieldValues === values)
        return Object.values(temp).every((x) => x === "");
    };
      
    const {
        values,
        setValues,
        handleInputChange,
        errors,
        setErrors,
        resetForm,
      } = useForm(initialPassword, true, validate);

      const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            pwdOrEdit(values, resetForm);
        }
      };
    
    

        return (
            <>
      <Form onSubmit={handleSubmit}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Controls.Input
              name="oldpassword"
              label="Contraseña antigua"
              type="password"
              value={values.oldpassword}
              onChange={handleInputChange}
              error={errors.oldpassword}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              name="password"
              label="Contraseña"
              type="password"
              value={values.password}
              onChange={handleInputChange}
              error={errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              name="password2"
              label="Confirmar Contraseña"
              type="password"
              value={values.password2}
              onChange={handleInputChange}
              error={errors.password2}
            />
          </Grid>
          
          <Grid item xs={10}>
            <div>
              <Controls.Button type="submit" text="Enviar" />
              <Controls.Button
                text="Limpiar"
                color="default"
                onClick={resetForm}
              />
            </div>
          </Grid>

        </Grid>

      </Form>

        </>
        );

}
export default CambiarPasswordModal;