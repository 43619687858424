import React, { useState } from "react";
import login from "servicios/loginService";
import { withRouter } from "react-router";
import LoginNavbar from "componentes/navbars/loginnavbar";
import "style/login.css";
import ImagenLogo from "../imagenes/logo2.jpg";

//TODO: Material-io
import { Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {jwtDecode} from "helpers/auth-helpers";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="">
        GRUPOSULCA
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
  //  backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundImage: `url(${ImagenLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [emailYPassword, setEmailYPassword] = useState({
    email: "",
    password: "",
  });

  function handleInputChange(e) {
    setEmailYPassword({
      ...emailYPassword,
      [e.target.name]: e.target.value,
    });
  }

  const [visible, setVisible] = useState("block");
  const [loading, setLoading] = useState(false);
  const [errormessage, setErrormessage] = useState(false);

  /** Funcion para el manejo del envio del formulario */
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let msg = "";
    setLoading(true);
    login
      .login(emailYPassword.email, emailYPassword.password)
      .then((r) => {
        console.log(r)
        let token = jwtDecode(r.data.token)
        console.log(token)
//        localStorage.setItem("user_name", `${r.data.user.nombre}`);
//        localStorage.setItem("user_id", `${r.data.user.id}`);
        localStorage.setItem("user_token", `${r.data.token}`);
//        localStorage.setItem("user_rol", `${r.data.user.rol}`);
//        localStorage.setItem("user_sede", `${r.data.user.sede}`);
        props.history.push("/home");
        setLoading({ loading: false });
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.message) {
          msg = e.response.data.message;
        } else {
          msg = "Error desconocido";
        }
        setErrormessage(msg);
        setLoading(false);
        setOpen(true);
      });
  };

  return (
    <>
      <LoginNavbar visible={visible}></LoginNavbar>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleInputChange}
                value={emailYPassword.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleInputChange}
                value={emailYPassword.password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {loading ? "Espere..." : "Entrar"}
              </Button>{" "}
              <Box mt={4}>
                <Copyright />
              </Box>
              <Box mt={5}>
                {errormessage ? (
                  <Snackbar
                    anchorOrigin={{ vertical: "center", horizontal: "center" }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="error">
                      Este es un mensaje de error - {errormessage}
                    </Alert>
                  </Snackbar>
                ) : (
                  ""
                )}
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(Login);
