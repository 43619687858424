import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CheckboxLabels(props) {
  const { name, label, value, checked, onChange, color='primary', disabled='' } = props;
  return (
    <FormGroup row>
    {color !== 'green' ?
    (<FormControlLabel style={{ marginLeft: 8 }} disabled={disabled}
        control={
          <Checkbox
            value={value}
            checked={checked}
            onChange={onChange}
            name={name}
            color={color}
          />}
        label={label}
      />) : (
        <FormControlLabel
          control={
          <GreenCheckbox 
            value={value}
            checked={checked} 
            onChange={onChange} 
            name={name} 
          />}
          label={label}
        />
      )}
    </FormGroup>
  );
}
export default CheckboxLabels;