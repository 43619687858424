import Axios from "axios";
const BASE_URL = "/sedes";

export default {
  save(worker) {
    return new Promise(function (resolve, reject) {
      Axios.post(`${BASE_URL}`, format(worker))
        .then((r) => {
          let res = r;
          res.data = parse(r.data);
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  update(worker) {
    return new Promise(function (resolve, reject) {
      Axios.put(`${BASE_URL}/${worker.id}`, format(worker))
        .then((r) => {
          let res = r;
          res.data = parse(r.data);
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  find() {
    return new Promise(function (resolve, reject) {
      Axios.get(BASE_URL)
        .then((r) => {
          let res = r;
          res.data = r.data.map((e) => parse(e));
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  findById(id) {
    return new Promise(function (resolve, reject) {
      Axios.get(`${BASE_URL}/${id}`)
        .then((r) => {
          let res = r;
          if (r.data.length > 0) {
            res.data = parse(r.data[0]);
          } else {
            res.data = {};
          }
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  sedellegada() {
    return new Promise(function (resolve, reject) {
      Axios.get(`${BASE_URL}/llegada`)
        .then((r) => {
          let res = r;
          if (r.data.length > 0) {
            res.data = r.data.map((e) => parse(e));
          } else {
            res.data = [];
          }
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  remove(id) {
    return new Promise(function(resolve, reject) {
        Axios.delete(`${BASE_URL}/${id}`).then((r)=> {
            let res = r;
            console.log(r.data)
  //                res.data = parse(r.data);
  //            resolve(res);
        }).catch(e=>reject(e));
    });
  },
  updateEditable(id,data) {
    return new Promise(function(resolve, reject) {
        Axios.put(`${BASE_URL}/editar/${id}`,data)
        .then((r)=> {
            let res = r;
            console.log(r)
            res.data = r.data;
            resolve(res);
        }).catch(e=>{console.log(e.datos);reject(e.message)});
    });
  },

  getEreport(id) {
    return new Promise(function (resolve, reject) {
      Axios.get(`${BASE_URL}/reporte/${id}`)
        .then((r) => {
          let res = r;
          res.data = r.data.map((e) => parse2(e));
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }


};


function format(data) {
  return {
    id: data.id,
    nombre: data.nombre,
    estado: data.estado ? Number(data.estado) : null,
    activa: data.activa,
  };
}

/** Traduce la data desde backend al formato del front */

const parse = (data) => {
  return {
    id: data.id,
    nombre: data.nombre,
    estado: data.estado,
    estadoNombre: data.estadoNombre,
    activa: data.activa
  };
};

const parse2 = (data) => {
  return {
    id: data.id,
    nombre: data.nombre,
  };
};
