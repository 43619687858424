import React, { useState, useEffect } from "react";

//servicios
import empresaService from "servicios/empresaService";
// helpers
import { getRol, getUserId } from "helpers/auth-helpers";
import { useParams } from "react-router-dom";
import { errorValues } from "../../helpers/helpers";
// Formulario
import EmpresasForm from "./EmpresasForm";
//import components
import ConfirmDialog from "componentes/ConfirmDialog";
import Controls from "componentes/controls/Controls";
import Notification from "componentes/controls/Notifications";
import Popup from "componentes/controls/Popup";
import swal from "sweetalert";
import useTable from "componentes/controls/useTable";

//material-ui
import {
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@material-ui/core";

//icons
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    overflowX: "auto",
    display: "fixed",
    width: "100%",
  },
  searchInput: {
    width: "30%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  tableCell: {
    fontSize: 11,
    size: "small",
    padding: theme.spacing(1),
  },
  tableCellIcon: {
    fontSize: 11,
    size: "small",
  },
}));

const headCells = [
  { id: "rif", label: "Rif", disableSorting: false },
  { id: "nombre", label: "Nombre", disableSorting: false },
  { id: "telefono", label: "Telefono", disableSorting: false },
  { id: "actions", label: "Acciones", disableSorting: true },
];

const headCellsNoActions = [
    { id: "rif", label: "Rif", disableSorting: false },
    { id: "nombre", label: "Nombre", disableSorting: false },
    { id: "telefono", label: "Telefono", disableSorting: false },
    { id: "actions", label: "Acciones", disableSorting: true },
  ];

function Empresa2() {
  let { id } = useParams();
  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [rol, setRol] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(
    empresas,
    rol.includes("1") ? headCells : headCellsNoActions,
    filterFn
  );

  const mensaje = () => {
    swal({ text: "Registro Exitoso", icon: "success" });
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.modelo.toLowerCase().includes(target.value)
          );
      },
    });
  };

  //manejo del envio del formulario add or edit
  const addOrEdit = (empresa, resetForm) => {
    let proc = (r) => {
        if (r.data.id) {
          setNotify({
            isOpen: true,
            message: "Registro ingresado con éxito!!!",
            type: "success",
          });
          mensaje();
        }
//          setProcessing(false);
      };
      let procError = (e) => {
//          console.log(e)
          setNotify({
            isOpen: true,
            message: e,
            type: "warning",
          });
          swal({ text: e, icon: "warning" });
      };

    if (empresa.id === 0) {
      try {
        
        empresaService.save(empresa).then(proc).catch(procError);
//        empresaService.create(empresa);
        //TODO: Verificar el error si el empresa existe
        getAllEmpresas();
        console.log("valores del empresa ingresado", empresa);
      } catch (e) {
        console.log("error =>", e);
      }
    } else {
      //llamar servicio para el update put /categorias/:id
      try {
        empresaService.update(empresa).then(proc).catch(procError);
        getAllEmpresas();
        setNotify({
          isOpen: true,
          message: "Registro actualizado con éxito!!!",
          type: "success",
        });
        console.log("valores del empresa editado", empresa);
      } catch (e) {
        console.log("error =>", e);
      }
    }
    resetForm();
    getAllEmpresas();
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  //enviar registro al Popup para edicion
  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  //funcion para borrar
  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    //llamar al servicio para borrar el registro
    try {
      //borrar registro
      empresaService.remove(id);
      getAllEmpresas();
      //console.log("borrando vehiculo", id);
      setNotify({
        isOpen: true,
        message: "Registro eliminado con éxito!!!",
        type: "error",
      });
    } catch (e) {
      console.log("error =>", e);
    }
    getAllEmpresas();
  };
  //funcion para mostrar las empresas
  async function getAllEmpresas() {
    try {
      let empresa = await empresaService.getAll();
      console.log("empresa =>", empresa.data);
      setEmpresas(empresa.data);
    } catch (err) {
      console.log("Error =>", err);
    }
  }

  useEffect(() => {
    getAllEmpresas();
    setRol(`${getRol()}`);
  }, []);

  return (
    <>
      <Controls.PageHeader
        title="Empresas"
        icon={<HomeWorkIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar empresa"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          {rol.includes("1") ? (
            <Controls.Button
              text="Nuevo"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          ) : (
            ""
          )}
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody className={classes.pageContent}>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.tableCell}>
                  {item.rif}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.nombre}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.telefono}
                </TableCell>
                {rol.includes("1") ? (
                  <TableCell className={classes.tableCell}>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        openInPopup(item);
                      }}
                    >
                      {" "}
                      <EditOutlinedIcon
                        className={classes.tableCellIcon}
                      />{" "}
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "¿ está seguro de querer borrar el registro ?",
                          subTitle: "esta operación es definitiva",
                          onConfirm: () => {
                            onDelete(item.id);
                          },
                        });
                      }}
                    >
                      {" "}
                      <CloseIcon className={classes.tableCellIcon} />{" "}
                    </Controls.ActionButton>
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="Empresas"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setRecordForEdit={recordForEdit}
      >
        <EmpresasForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default Empresa2;
