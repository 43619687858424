import React from "react";

//material ui
import {
  Card,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      padding: 0,
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marging: theme.spacing(0.5),
      padding: theme.spacing(0),
      paddingBottom: theme.spacing(2),
    },
    "& > * + *": {
      marginTop: theme.spacing(0.5),
    },
  },
  pageContent: { marging: theme.spacing(1), padding: theme.spacing(1) },

  pageHeader: {
    padding: theme.spacing(0.5),
    display: "flex",
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary,
    width: "100%",
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#607d8b",
  },
  pageTitle: {
    paddingLeft: theme.spacing(4),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
}));

function PageHeader(props) {
    const classes = useStyles();
    const { title, subtitle, icon } = props;
  return (
    <Paper square className={classes.root}>
      <div className={classes.pageHeader}>
        <Card className={classes.pageIcon}>{icon}</Card>
        <div className={classes.pageTitle}>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {subtitle}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

export default PageHeader;
