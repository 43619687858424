import React, { useState } from "react";

//material ui
import { makeStyles, Paper } from "@material-ui/core";
import { esES } from "@material-ui/core/locale";

export function useForm(initialFValues,validateOnChange=false, validate) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    })
    if (validateOnChange)
      validate({ [name]: value })
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({})
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  };
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      "& .MuiInputBase-root": {
        padding: 0.5,
        marginRight: theme.spacing(1),
      },
      "& .MuiFormControl-root": {
        width: "100%",
        margin: theme.spacing(0.5),
        padding: theme.spacing(0),
        paddingBottom: theme.spacing(2),
      },
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    pageContent: { margin: theme.spacing(0.5), padding: theme.spacing(0.5) },
    media: {
      height: 200,
    },
    paper: {
      paddingVertical: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      paddingBottom: 6,
      paddingTop: 6,
      paddingLeft: 8,
      paddingRight: 8,
    },
    fontFecha: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(0),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      width: 200,
    },
    textarea: {
      width: "90%",
    },
    normalLong: {
      width: "25%",
    },
  }),
  esES
);

export function Form(props) {
  const classes = useStyles();
  const { children, ...other } = props;
  return (
    <Paper className={classes.paper}>
      <form className={classes.root} autoComplete="off" {...other}>
        {props.children}
      </form>
    </Paper>
  );
}
