import React, { Component } from "react";
import "style/login.css";
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from "react-router";

class AddSpinner extends Component {

  render() {
    return (
    		<>
				<Spinner className="spin sep-label" animation={this.props.animation?this.props.animation:'border'} 
				role="status" size="sm" variant={this.props.variant}>
					<span className="sr-only">Loading...</span>
				</Spinner>	
			</>
		);
	}

}

export default withRouter(AddSpinner)