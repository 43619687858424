import React, { Component } from "react";
import { Form, Col, Button, Accordion, Card } from "react-bootstrap";
import { withRouter } from "react-router";
import empresaService from "servicios/empresaService";
import ProcesandoModal from "componentes/comunes/ProcesandoModal";
import ErrorModal from "componentes/comunes/ErrorModal";
import InfoModal from "componentes/comunes/InfoModal";
import "style/formularios.css";
class Personal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //user data
      id: 0,
      rif: "",
      nombre: "",
      telefono: "",
      //view
      empresalist: [],
      //view state
      formvalidated: false,
      showSearch: true,
      processing: false,
      showError: false,
      errorMsg: "",
      infoMsg: "",
      showInfo: false,
    };
    this.handleSubmit.bind(this);
    this.saveProcess.bind(this);
    this.componentDidMount.bind(this);

    }

  componentDidMount() {
    if (this.state.empresalist.length <= 0) {
      empresaService.find().then((r) => {
        let list = r.data;
        list.unshift({ id: 0, name: "Seleccione una empresa..." });
        this.setState({ banks: list });
      });
    }
    if (this.props.match && this.props.match.params.id) {
      if (!this.state.processing) {
        this.setState({
          processing: true,
        });
      }
      empresaService
        .findById(this.props.match.params.id)
        .then((r) => {
            console.log(r.data)
          let data = r.data;
          data.processing = false;
          this.setState(data);
        })
        .catch((e) => {
          this.setState({
            processing: false,
          });
          this.props.history.push(`/empresa`);
        });
    }
  }

  componentDidUpdate() {
    if ((!this.props.match || !this.props.match.params.id) && this.state.id) {
      this.setState({
        //user data//user data
        id: 0,
        rif: "",
        nombre: "",
        telefono: "",
  
      });
    }
  }

  componentWillUnmount() {}

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      this.setState({ formvalidated: false });
    } else {
      this.setState({ formvalidated: true });
      this.saveProcess();
    }
    this.setState({ formvalidated: true });
  };

  saveProcess() {
    this.setState({
      processing: true,
    });

    let proc = (r) => {
      let data = {};
      if (this.state.id) {
        data = r.data;
        data.showInfo = true;
        data.infoMsg = "El registro se guardó satisfactoriamente.";
      }
      data.processing = false;
      this.setState(data);
      if (!this.state.id) {
        this.props.history.push(`/empresa/${r.data.id}`);
      }
    };

    let procError = (r) => {
      console.log(r.response)
      if (r.response && r.response.data && r.response.data.message) {
        this.setState({
          processing: false,
          showError: true,
          errorMsg: r.response.data.message,
        });
      } else {
        if(r.response && r.response.data && r.response.data.errors && r.response.data.errors.rif){
          this.setState({
            processing: false,
            showError: true,
            errorMsg: r.response.data.errors.rif,
          });
        } else if(r.response && r.response.data && r.response.data.errors && r.response.data.errors.nombre){
          this.setState({
            processing: false,
            showError: true,
            errorMsg: r.response.data.errors.nombre,
          });
        }else{
          this.setState({
            processing: false,
            showError: true,
            errorMsg: "Error guardando el registro.",
          });
        }
      }
    };

    if (this.state.id && this.state.id > 0) {
      empresaService.update(this.state).then(proc).catch(procError);
    } else {
      empresaService.save(this.state).then(proc).catch(procError);
    }
  }

  render() {
    return (
      <>
        <InfoModal
          show={this.state.showInfo}
          title="Procesado"
          message={this.state.infoMsg}
          closeCallback={() => this.setState({ showInfo: false })}
        ></InfoModal>
        <ProcesandoModal show={this.state.processing} />
        <ErrorModal
          show={this.state.showError}
          message={this.state.errorMsg}
          closeCallback={() => {
            this.setState({ showError: false });
          }}
        />
        <Form
          noValidate
          validated={this.state.formvalidated}
          onSubmit={this.handleSubmit}
        >
          <Card>
            <Card.Header className="card-header">Datos de la Empresa</Card.Header>
            <Card.Body>
              <Form.Row>
                <Form.Group as={Col} controlId="nombre">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    value={this.state.nombre}
                    autoComplete="off"
                    onChange={(event) => {
                      this.setState({ nombre: event.target.value });
                    }}
                    required
                    type="text"
                    placeholder="Nombre"
                  />
                  <Form.Control.Feedback type="invalid">
                    El nombre de la empresa es obligatorio.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="rif">
                  <Form.Label>RIF</Form.Label>
                  <Form.Control
                    value={this.state.rif}
                    autoComplete="off"
                    onChange={(event) => {
                      this.setState({ rif: event.target.value });
                    }}
                    required
                    maxLength="12"
                    type="text"
                    placeholder="RIF J-12345678-1"
                  />
                  <Form.Control.Feedback type="invalid">
                    El RIF de la empresa es obligatorio.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="telefono">
                  <Form.Label>Telefono</Form.Label>
                  <Form.Control
                    value={this.state.telefono}
                    autoComplete="off"
                    onChange={(event) => {
                      this.setState({ telefono: event.target.value });
                    }}
                    type="text"
                    placeholder="Teléfono"
                  />
                  <Form.Control.Feedback type="invalid">
                    El Teléfono de la empresa es obligatorio.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

            </Card.Body>
          </Card>

          <Form.Row className="align-items-center my-2">
            <Col className="mx-8">
              <span></span>
            </Col>
            <Col className="mx-8">
              <Button type="submit" variant="primary" block>
                Guardar Cambios
              </Button>
            </Col>
            <Col className="mx-8">
              <span></span>
            </Col>
          </Form.Row>
        </Form>
      </>
    );
  }
}

export default withRouter(Personal);
