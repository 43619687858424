import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";

function Select(props) {
  const {
          name,
          label,
          value,
          Tam,
          error=null,
          onChange,
          options,
          campo='name'
        } = props;

  return (
    <FormControl
      {...(error && { error: true })}
      variant="outlined"
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        style={{width: Tam, margin:"4px"}}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...props}
      >
        <MenuItem value="">Seleccione uno...</MenuItem>
        {options.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            {item[campo]}
          </MenuItem>
        ))}
      </MuiSelect>
      { error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default Select;
