import React, { Component } from 'react';
import { Form, Card, Col, Button, Table, Accordion,OverlayTrigger,Tooltip } from 'react-bootstrap';
import { withRouter } from "react-router";
import personalService from "servicios/personalService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ProcesandoModal from 'componentes/comunes/ProcesandoModal';
import "style/formularios.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel'; 
class PersonalReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //user data
            id: 0,
            name: this.props.history.location.state && this.props.history.location.state.name?this.props.history.location.state.name:'',
            dni: this.props.history.location.state && this.props.history.location.state.dni?this.props.history.location.state.dni:'',
            email: this.props.history.location.state && this.props.history.location.state.email?this.props.history.location.state.email:'',
            //view state
            processing: false,
            activeAcordeon: this.props.history.location.state && this.props.history.location.state.activeAcordeon?this.props.history.location.state.activeAcordeon:'params',
            reportdata: this.props.history.location.state && this.props.history.location.state.reportdata?this.props.history.location.state.reportdata:[],
        };
        this.handleSubmit.bind(this);
        this.saveProcess.bind(this);

        
    }
    


    handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ formvalidated: false });
        } else {
            this.setState({ formvalidated: true });
        }
        this.setState({ formvalidated: true });
        event.preventDefault();
        event.stopPropagation();
        this.saveProcess();
    };

    saveProcess() {
        personalService.report(this.state).then((r) => {
            let data = r.data;
            this.setState({ reportdata: data, processing: false, activeAcordeon: '' });
        });
    }

    render() {
        return (
            <>
                <ProcesandoModal show={this.state.processing} />
                <Form onSubmit={this.handleSubmit} style={{ maxWidth: "700px" }} className="mx-auto">
                    <Form.Row >
                        <Col>
                        <Accordion activeKey={this.state.activeAcordeon} 
                        className="my-3" 
                        onSelect={e => this.setState({ activeAcordeon: e })}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="params" >
                                    Parámetros
                            </Accordion.Toggle>
                                <Accordion.Collapse eventKey="params">
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId="name">
                                                <Form.Label>Nombre</Form.Label>
                                                <Form.Control
                                                    autoComplete="off"
                                                    value={this.state.name}
                                                    onChange={(event) => { this.setState({ name: event.target.value }); }}
                                                    type="text" placeholder="Nombre" />
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId="dni">
                                                <Form.Label>Cédula</Form.Label>
                                                <Form.Control
                                                    value={this.state.dni}
                                                    autoComplete="off"
                                                    onChange={(event) => { this.setState({ dni: `${event.target.value}` }); }}
                                                    type="number"
                                                    placeholder="Cédula" />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col} controlId="email">
                                                <Form.Label>Correo Electrónico</Form.Label>
                                                <Form.Control
                                                    value={this.state.email}
                                                    autoComplete="off"
                                                    onChange={(event) => { this.setState({ email: `${event.target.value}` }); }}
                                                    placeholder="ejemplo@ejemplo.com" />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row className="align-items-center my-8">
                                            <Col className="mx-8">
                                                <span></span>
                                            </Col>
                                            <Col className="mx-8">
                                                <Button type="submit" block>Generar Reporte</Button>
                                            </Col>
                                            <Col className="mx-8">
                                            <Col className="mx-8">
                                                    <ReactHTMLTableToExcel  
                                                    className="btn btn-primary btn-block"  
                                                    table="personal-table"  
                                                    filename="Personal"  
                                                    sheet="Sheet"  
                                                    buttonText="Exportar" block/>  
                                                </Col>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        </Col>
                    </Form.Row>
                </Form>
                <Table id="personal-table" striped bordered hover size="sm"   >
                            <thead>
                                <tr>
                                    <th>Cédula</th>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody className="row-cursor-pointer">
                                {this.state.reportdata.map((w, index) =>
                                    <tr key={index}>
                                        <td>{w.dnitype}{w.dni}</td>
                                        <td className="text-nowrap text-uppercase">{`${w.name} ${w.lastname}`}</td>
                                        <td className="text-lowercase">{w.email}</td>
                                        <td className="column-centred">
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="button-tooltip-2">Editar Registro</Tooltip>}
                                            >
                                                 <FontAwesomeIcon 
                                                    icon={faEdit} 
                                                    onClick={()=>this.props.history.push(`/personal/${w.id}`)}/>
                                            </OverlayTrigger>
                                            </td>

                                    </tr>
                                )}
                            </tbody>
                        </Table>


            </>
        );
    }
}

export default withRouter(PersonalReport);